import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EXAMS_URL } from "modules/exams-marksheets/constants/exams-url";
import { axiosService } from "services/axios.service";
import { toast } from "react-toastify";
import Sortable from "sortablejs";
import "../faculty-exams/FacultyExams.css";
import Swal from "sweetalert2";
import "./StudentExams.css";

function StudentAnswerSubmit() {
  const { state } = useLocation();
  const { selectedExam, sendData } = state || {};

  const parseDurationToSeconds = (duration) => {
    const [hoursMinutes] = duration.split(" hrs");
    const [hours, minutes] = hoursMinutes.split(":").map(Number);
    return hours * 60 * 60 + minutes * 60;
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  const [timeLeft, setTimeLeft] = useState(
    parseDurationToSeconds(sendData?.duration)
  );
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      handleSubmit();
    }
  }, [timeLeft]);

  const [section, setSection] = useState(1);

  const data = selectedExam;

  const totalSections = data.sections.length;
  const totalQuestions = data.sections.reduce(
    (acc, sec) => acc + sec.questions.length,
    0
  );

  const [answers, setAnswers] = useState({});

  const getSectionStartIndex = (sectionIndex) => {
    return data.sections
      .slice(0, sectionIndex)
      .reduce((acc, sec) => acc + sec.questions.length, 0);
  };

  const handleOptionChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { ...prevAnswers[questionId], selectedOption: value },
    }));
  };
  const handleTrueOrFalse = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: {
        ...prevAnswers[questionId],
        selectedBooleanOption: value,
      },
    }));
  };

  const handleOptionsChange = (questionId, value) => {
    setAnswers((prevAnswers) => {
      const currentSelectedOptions =
        prevAnswers[questionId]?.selectedOptions || [];
      const newSelectedOptions = currentSelectedOptions.includes(value)
        ? currentSelectedOptions.filter((opt) => opt !== value)
        : [...currentSelectedOptions, value];
      return {
        ...prevAnswers,
        [questionId]: {
          ...prevAnswers[questionId],
          selectedOptions: newSelectedOptions,
        },
      };
    });
  };

  const handleTextChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { ...prevAnswers[questionId], textAnswer: value },
    }));
  };

  const handleTextParaChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { ...prevAnswers[questionId], textParaAnswer: value },
    }));
  };
  const handleEssayChange = (questionId, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { ...prevAnswers[questionId], textEssayAnswer: value },
    }));
  };
  const navigation = useNavigate();
  const handleSubmit = async () => {
    const requestData = {
      studentQuestionAnswerId: sendData?.studentQuestionAnswerId,
      master: {
        studentMasterId: sendData?.master?.studentMasterId,
      },
      examModule: {
        facultyExamModuleId: data?.facultyExamModuleId,
      },
      answer: {
        sections: data.sections.map((section) => ({
          sectionTitle: section.sectionTitle,
          duration: section.duration,
          marks: section.marks,
          sectionListId: section.sectionListId,
          questions: section.questions.map((question) => {
            let answerData = {
              questionId: question?.questionId,
              questionType: question?.questionType,
              question: question?.question,
              marks: question?.marks,
              correctedOption: question?.questions?.correctedOption || null,
              correctedAnswer: question?.questions?.correctedAnswer || null,
              correctedOptions: question?.questions?.correctedOptions || null,
              leftSideOptions: question?.questions?.leftSideOptions || null,
              multipleOptions: question?.questions?.multipleOptions || null,
              options: question?.questions?.options || null,
              correctedBooleanOption:
                question?.questions?.correctedBooleanOption || null,
            };

            const answerDatas = {
              ...answerData,
              ...answers[question?.questionId],
            };
            return answerDatas;
          }),
        })),
      },
      examName: sendData?.examName,
      examDate: sendData?.examDate,
      examTime: sendData?.examTime,
      examEndDate: sendData?.examEndDate,
      endTime: sendData?.endTime,
    };

    try {
      const response = await axiosService.put(
        `${EXAMS_URL.SAVE_STUDENT_EXAM_MODULE}`,
        requestData
      );

      if (response.status === 200) {
        toast.success("Exam Submitted Successfully");
        navigation("/exam-marksheets/my-exams");
      } else {
        toast.error("Error Occurred");
      }
    } catch (error) {
      toast.error(`Error: ${error.response.data.message}`);
    }
  };

  const handleAlert = () => {
    Swal.fire({
      title: "Confirm Submit",
      html: "Are you sure you want to Submit the Answer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  const [rearrangedAnswers, setRearrangedAnswers] = useState({});

  useEffect(() => {
    const initialRearrangedAnswers = {};
    data.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.questionType === "Match the following") {
          const questionId = question.questionId;
          const answers = question.questions.leftSideOptions.map(
            (option) => option.answer
          );
          initialRearrangedAnswers[questionId] = answers;
        }
      });
    });
    setRearrangedAnswers(initialRearrangedAnswers);
  }, [data]);

  useEffect(() => {
    data.sections[section - 1].questions.forEach((q) => {
      const dropItems = document.getElementById(
        `rearrange-drop-items-${q.questionId}`
      );
      if (dropItems) {
        const isMobile = window.innerWidth <= 250;
        const sortable = Sortable.create(dropItems, {
          animation: 350,
          handle: ".rearrange-drop-name",
          touch: isMobile ? true : false,
          onChange: () => {
            handleRearrange(q);
          },
        });

        return () => sortable.destroy();
      }
    });
  }, [data, section]);

  const [renderData, setRenderData] = useState(null);

  function handleRearrange(q) {
    let sortedHTML = document.getElementsByClassName("rearrange-drop-name");
    let innerTextSet = new Set();

    for (let i = 0; i < sortedHTML.length; i++) {
      innerTextSet.add(sortedHTML[i].innerText.trim());
    }

    let innerTexts = Array.from(innerTextSet);

    const mergedQuestions = q.questions.leftSideOptions.map((q, index) => ({
      ...q,
      answer: innerTexts[index],
    }));

    setRearrangedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [q.questionId]: innerTexts,
    }));

    setRenderData({
      [q.questionId]: rearrangedAnswers[q.questionId]?.map((answer, index) => (
        <div
          style={{
            border: "1px solid #ccc",
            margin: "4px",
            padding: "8px",
            backgroundColor: "#333333",
            color: "#ffffff",
            cursor: "grab",
          }}
          className="rearrange-drop-name"
        >
          {answer}
        </div>
      )),
    });

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [q.questionId]: {
        ...prevAnswers[q.questionId],
        studentAnsweredMatchOptions: mergedQuestions,
      },
    }));
  }
  const tfOptions = ["True", "False"];
  return (
    <div className="exam-management-main-container">
      <div className="communication-management-head-container sticky-header student-answer-Head">
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <span className="communication-management-title-text">My Exams</span>
          <p style={{ color: "#ffffff" }}>{formatTime(timeLeft)}</p>
        </div>
        <div className="student-answer-header">
          <span className="answer-inner-header">
            <div className="my-exam-header-detail-value-container border-end">
              <div className="my-exam-header-detail-value">Total Sections</div>
              <div className="my-exam-header-detail-data">{totalSections}</div>
            </div>
            <div className="my-exam-header-detail-value-container border-end">
              <div className="my-exam-header-detail-value">Total Questions</div>
              <div className="my-exam-header-detail-data">{totalQuestions}</div>
            </div>
          </span>

          <span className="answer-inner-header col-md-6 d-md-flex">
            <div className="my-exam-header-detail-value-container">
              <div className="my-exam-header-detail-value">Classname</div>
              <div className="my-exam-header-detail-data flex-wrap">
                {data?.module?.programFeeMaster?.program}
              </div>
            </div>
          </span>
        </div>
      </div>

      <div className="my-exam-questions-section-heading-holder">
        {data.sections.map((sec, index) => (
          <button
            key={sec.sectionListId}
            onClick={() => setSection(index + 1)}
            className={
              section === index + 1 ? "my-exam-questions-selected-section" : ""
            }
          >
            Section {index + 1}
          </button>
        ))}
      </div>
      <div className="questions-container">
        {data.sections[section - 1].questions.map((q, qIndex) => {
          const currentSectionStartIndex = getSectionStartIndex(section - 1);
          const currentQuestionNumber = currentSectionStartIndex + qIndex + 1;

          return (
            <div>
              <div>
                <div key={q.questionId} style={{ color: "#ffffff" }}>
                  <div>
                    <div
                      style={{
                        borderRadius: "10px",
                        color: "white",
                        backgroundColor: "#333333",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="my-exam-question-question-no-div">
                        Question {currentQuestionNumber}/{totalQuestions}
                      </div>

                      <div
                        className="my-exam-question-question-div"
                        dangerouslySetInnerHTML={{ __html: q.question }}
                        style={{ margin: "0px" }}
                      ></div>

                      <div style={{ padding: "10px 3px" }}>
                        {q.questionType === "Multi-choice questions (MCQ)" && (
                          <div style={{ margin: "10px 3px 10px 10px" }}>
                            <span className="my-exam-select-div">
                              Select an answer:
                            </span>
                            <div>
                              {q.questions.options.map((option, index) => (
                                <div key={index} style={{ padding: "2px 0px" }}>
                                  <input
                                    style={{ marginRight: "5px" }}
                                    type="radio"
                                    name={`mcq-${q.questionId}`}
                                    value={option}
                                    checked={
                                      answers[q.questionId]?.selectedOption ===
                                      option
                                    }
                                    onChange={(e) =>
                                      handleOptionChange(
                                        q.questionId,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label>{option}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {q.questionType === "True or False" && (
                          <div style={{ margin: "10px 3px 10px 10px" }}>
                            <span className="my-exam-select-div">
                              Select an answer:
                            </span>
                            <div>
                              {tfOptions.map((option, index) => (
                                <div key={index} style={{ padding: "2px 0px" }}>
                                  <input
                                    style={{ marginRight: "5px" }}
                                    type="radio"
                                    name={`mcq-${q.questionId}`}
                                    value={option}
                                    checked={
                                      answers[q.questionId]
                                        ?.selectedBooleanOption === option
                                    }
                                    onChange={(e) =>
                                      handleTrueOrFalse(
                                        q.questionId,
                                        e.target.value
                                      )
                                    }
                                  />
                                  <label>{option}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {q.questionType === "Multi-select questions (MSQ)" && (
                          <div style={{ margin: "10px 3px 10px 10px" }}>
                            <span className="my-exam-select-div">
                              Select an answer:
                            </span>
                            <div>
                              {q.questions.multipleOptions.map(
                                (option, index) => (
                                  <div
                                    key={index}
                                    style={{ padding: "2px 0px" }}
                                  >
                                    <input
                                      style={{ marginRight: "5px" }}
                                      type="checkbox"
                                      name={`msq-${q.questionId}`}
                                      value={option}
                                      checked={(
                                        answers[q.questionId]
                                          ?.selectedOptions || []
                                      ).includes(option)}
                                      onChange={(e) =>
                                        handleOptionsChange(
                                          q.questionId,
                                          e.target.value
                                        )
                                      }
                                    />
                                    <label>{option}</label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                        {q.questionType ===
                          "Write short notes on the following" && (
                          <div
                            className="d-flex row m-0"
                            style={{ padding: "0px 10px" }}
                          >
                            <span
                              className="my-exam-select-div "
                              style={{
                                padding: "0px 0px 7px 0px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              Type Your answer
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  margin: "0px",
                                }}
                              >
                                (max Words:{q.maxWords})
                              </p>
                            </span>
                            <textarea
                              maxLength={q.maxWords}
                              className="fill-textarea-box"
                              value={
                                answers[q.questionId]?.textParaAnswer || ""
                              }
                              onChange={(e) =>
                                handleTextParaChange(
                                  q.questionId,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        )}
                        {q.questionType === "Essay Writing" && (
                          <div
                            className="d-flex row m-0"
                            style={{ padding: "0px 10px" }}
                          >
                            <span
                              className="my-exam-select-div "
                              style={{
                                padding: "0px 0px 7px 0px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              Type Your answer
                            </span>
                            <textarea
                              className="fill-textarea-box"
                              value={
                                answers[q.questionId]?.textEssayAnswer || ""
                              }
                              onChange={(e) =>
                                handleEssayChange(q.questionId, e.target.value)
                              }
                            />
                          </div>
                        )}
                        {q.questionType === "Match the following" && (
                          <div style={{ marginLeft: "10px" }}>
                            <span className="my-exam-select-div">
                              Match the answer:
                            </span>
                            <div className="d-flex">
                              <div style={{ width: "40%" }}>
                                {q.questions.leftSideOptions.map(
                                  (option, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        border: "1px solid #ccc",
                                        margin: "4px",
                                        padding: "8px",
                                        backgroundColor: "#333333",
                                        color: "#ffffff",
                                      }}
                                    >
                                      {option.question}
                                    </div>
                                  )
                                )}
                              </div>
                              <div style={{ width: "40%", marginLeft: "5%" }}>
                                <div>
                                  <div
                                    id={`rearrange-drop-items-${q.questionId}`}
                                    style={{ minHeight: "100px" }}
                                  >
                                    {(renderData && renderData[q.questionId]) ||
                                      rearrangedAnswers[q.questionId]?.map(
                                        (answer, index) => (
                                          // <div className="rearrange-drop-card">
                                          <div
                                            style={{
                                              border: "1px solid #ccc",
                                              margin: "4px",
                                              padding: "8px",
                                              backgroundColor: "#333333",
                                              color: "#ffffff",
                                              cursor: "grab",
                                            }}
                                            className="rearrange-drop-name"
                                          >
                                            {answer}
                                          </div>
                                          // </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {q.questionType === "Fill in the blanks" && (
                          <div
                            className="d-flex row m-0"
                            style={{ padding: "0px 10px" }}
                          >
                            <span
                              className="my-exam-select-div "
                              style={{ padding: "0px 0px 7px 0px" }}
                            >
                              Type Your answer
                            </span>
                            <input
                              value={answers[q.questionId]?.textAnswer || ""}
                              className="fill-input-box"
                              onChange={(e) =>
                                handleTextChange(q.questionId, e.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="d-flex gap-2">
        {section !== 1 && (
          <button
            className="communication-management-cancel-button"
            onClick={() => setSection(section - 1)}
          >
            Back
          </button>
        )}
        {section < totalSections && (
          <button
            className="communication-management-cancel-button"
            onClick={() => setSection(section + 1)}
          >
            Next
          </button>
        )}
        {section === totalSections && (
          <button
            className="communication-save-head-button"
            onClick={handleAlert}
            style={{ height: "33px" }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default StudentAnswerSubmit;
