import { AppConfig } from "env.development";

export const DOCUSIGN_URL = {
  SEND_ENVELOPE_FOR_AGREEMENT: `${AppConfig.api_url_}docusign/v1/send`,
  SEND_AGREEMENT_ENVELOPE: `${AppConfig.api_url_}docusign/v2/send`,
  SEND_AGREEMENT_ENVELOPE: `${AppConfig.api_url_}docusign/v2/send`,
  PRE_DOC_CALLBACK_FUNCTION: `${AppConfig.api_url_}docusign/v2/callback`,
  PRE_DOC_CALLBACK_FUNCTION_ENROLL: `${AppConfig.api_url_}docusign/v3/callback`,
  HANDLE_CALLBACK_FOR_AGREEMENT: `${AppConfig.api_url_}docusign/v1/callback`,
  GET_BASE_IMAGE: `${AppConfig.api_url_}api/dashboard/v1/image`,
};
