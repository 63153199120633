import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useEffect, useState } from "react";
import { axiosService } from "services/axios.service";
import { RxTriangleDown } from "react-icons/rx";
import Select from "react-select";
import "./ManageMarks.css";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import EditIcon from "assets/image-components/EditIcon";
import { EXAM_MODULE_URLS } from "modules/exam-module/constants/exam-modules-urls";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";

function StudentMarks() {
  const [examData, setExamData] = useState([]);
  const [studentIds, setStudentIds] = useState([]);
  const [studentId, setStudentId] = useState(null);
  const [studentName, setStudentName] = useState(null);
  const [programName, setProgramName] = useState("");

  const GradeOptions = [
    { value: "A", label: "A" },
    { value: "A_PLUS", label: "A+" },
    { value: "B", label: "B" },
    { value: "B_PLUS", label: "B+" },
    { value: "C", label: "C" },
    { value: "C_PLUS", label: "C+" },
    { value: "D", label: "D" },
    { value: "D_PLUS", label: "D+" },
  ];
  const ResultOptions = [
    { value: "PASSED", label: "Passed" },
    { value: "FAILED", label: "Failed" },
    { value: "WITHDRAWAL", label: "Withdrawal" },
  ];
  const StatusOptions = [
    { value: "COMPLETED", label: "Completed" },
    { value: "INCOMPLETE", label: "In-Complete" },
    { value: "INPROGRESS", label: "In-Progress" },
  ];
  useEffect(() => {
    axiosService.get(EXAM_MODULE_URLS.GET_STUDENT_TABLE1);
  }, []);

  useEffect(() => {
    const getStudentIds = async () => {
      try {
        const response = await axiosService.getForUrl(
          FEE_URLS.GET_ALL_STUDENTS
        );
        const data = response?.data?.data;

        const ids = data.map((student) => ({
          value: student.studentId,
          label: student.studentId,
        }));
        setStudentIds(ids);
      } catch (error) {
        console.error("Error fetching student IDs:", error);
      }
    };

    getStudentIds();
  }, []);

  const [editMode, setEditMode] = useState({});
  const handleEdit = (index) => {
    const updatedEditMode = { ...editMode };
    updatedEditMode[index] = true;
    setEditMode(updatedEditMode);
  };

  const getStudentDetails = async (value) => {
    try {
      const response = await axiosService.getForUrl(
        `${FEE_URLS.GET_STUDENT_DETAILS_BY_STUDENT_ID}${value}`
      );
      const data = response?.data?.data?.admission;
      setStudentName(data?.personalData?.firstName);
      setProgramName(data?.programDetails[0]?.program);
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  };
  const getUpdatedMarks = async (value) => {
    try {
      const response = await axiosService.getForUrl(
        `${FEE_URLS.GET_STUDENT_UPDATED_MARKS}?studentId=${value}`
      );
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  };

  const [manageMarks, setManageMarks] = useState([]);
  const getAllMarkList = async (value) => {
    try {
      const response = await axiosService.get(
        `${EXAM_MODULE_URLS.GET_STUDENT_TABLE}?studentId=${value}`
      );
      const data = response?.data?.data;
      setExamData(data);
      setManageMarks(data);
      const initialEditMode = {};
      data.forEach((item, index) => {
        initialEditMode[index] = false;
      });
      setEditMode(initialEditMode);
    } catch (error) {
      console.error("Error fetching student marks:", error);
    }
  };

  const handleChange = (newValue, rowIndex, field) => {
    const updatedData = [...examData];
    if (
      typeof newValue === "object" &&
      newValue !== null &&
      newValue.hasOwnProperty("value")
    ) {
      updatedData[rowIndex][field] = newValue.value;
    } else {
      updatedData[rowIndex][field] = newValue.target.value;
    }

    if (field === "startDate") {
      const startDate = new Date(updatedData[rowIndex]["startDate"]);
      const endDate = new Date(startDate.getTime());
      endDate.setDate(endDate.getDate() + 98);
      const formattedEndDate = endDate.toISOString().split("T")[0];

      updatedData[rowIndex]["endDate"] = formattedEndDate;
    }

    setExamData(updatedData);
  };

  const handleSave = async (isNotify) => {
    try {
      const response = await axiosService.put(
        `${EXAM_MODULE_URLS.SAVE_ALL_STUDENT_MARKS}?isNotify=${isNotify}`,
        examData
      );
      if (response.status === 200) {
        toast.success("Student Marks Saved Successfully!");
        setManageMarks([]);
        setExamData([]);
        setStudentId("");
        setStudentName("");
        setProgramName("");
      }
    } catch (error) {
      console.error("Error saving row:", error);
      toast.error(`${error?.response?.data?.message.split(":")[1]?.trim()}`);
    }
  };

  const handleEditSave = async (editedData, isNotify) => {
    try {
      const response = await axiosService.put(
        `${EXAM_MODULE_URLS.SAVE_STUDENT_MARK}?isNotify=${isNotify}`,
        editedData
      );
      if (response.status === 200) {
        toast.success("Student Mark Saved Successfully!");
        getAllMarkList(studentId);
      }
    } catch (error) {
      console.error("Error saving row:", error);
      toast.error("Failed to save student marks. Please try again.");
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#222222 !important",
    }),
  };

  const selectStylesDD = {
    control: (provided) => ({
      ...provided,
      borderRadius: "0 !important",
      width: "100px !important",
      backgroundColor: "#222222 !important",
    }),
  };
  const handleUpdateSubmit = async () => {
    await handleSave(false);
  };

  const handleUpdateNotify = async () => {
    await handleSave(true);
  };
  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container sticky-header align-items-center flex-wrap flex-row gap-2">
        <span className="lead-table-title-text">MarkSheet Management</span>
        <div className="d-flex align-items-center gap-3">
          <button
            className="communication-save-head-button"
            onClick={handleUpdateSubmit}
          >
            Save Marks
          </button>
          <button
            className="communication-save-head-button"
            onClick={handleUpdateNotify}
          >
            Save & Notify
          </button>
        </div>
      </div>
      <div className="manage-marks-program-con">
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <div className="col-md-3 col-8 align-items-center">
            <span className="ag-header-cell-text">Student ID</span>
            <Select
              value={studentId}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setStudentId(selectedOption);
                  getUpdatedMarks(selectedOption.value);
                  setStudentName("");
                  setProgramName("");
                  setManageMarks([]);
                  setExamData([]);
                }
              }}
              options={studentIds}
              isSearchable={true}
              classNamePrefix="react-select"
              styles={selectStyles}
              components={{
                DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                IndicatorSeparator: null,
              }}
              placeholder="Choose an Option"
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </div>
          <div style={{ marginTop: "25px" }}>
            <button
              onClick={() => {
                if (studentId) {
                  getStudentDetails(studentId.value);
                  getAllMarkList(studentId.value);
                }
              }}
              className={`communication-management-cancel-button ${
                studentId
                  ? "opacity-100 cursor-pointer"
                  : "opacity-50 cursor-context-menu"
              }`}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      {studentName && (
        <div className="manage-marks-program-con">
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div className="col-md-3 col-12 align-items-center">
              <span className="ag-header-cell-text">Student Name</span>
              <input value={studentName} className="student-marks-input" />
            </div>
            <div className="col-md-5 col-12 align-items-center">
              <span className="ag-header-cell-text">Program Name</span>
              <input
                value={programName
                  .toLowerCase()
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
                className="student-marks-input col-12"
                style={{ textOverflow: "ellipsis", fontSize: "13.5 px" }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="marks-table-container table-container">
        <div className="custom-scrollbar" style={{ overflow: "auto" }}>
          <table className="w-100">
            <thead className="table-head">
              <tr className="marks-head-table">
                <th>Code</th>
                <th>Module Name</th>
                <th>Marks in %</th>
                <th>Grade</th>
                <th>Result</th>
                <th>Status</th>
                <th>Start Date</th>
                <th>End Date</th>
                {manageMarks &&
                  manageMarks.length > 0 &&
                  manageMarks[0]?.markPercentage &&
                  manageMarks[0]?.result &&
                  manageMarks[0]?.status &&
                  manageMarks[0]?.startDate &&
                  manageMarks[0]?.endDate && <th>Actions</th>}
              </tr>
            </thead>
            <tbody className="table-data">
              {examData.map((item, index) => (
                <tr key={index} className="home-task-table gap-5">
                  <td>{item.module?.module?.moduleCode}</td>
                  <td>{item.module?.module?.moduleName}</td>
                  <td>
                    <input
                      className="table-input-box"
                      value={item.markPercentage}
                      onChange={(e) => handleChange(e, index, "markPercentage")}
                      disabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    <Select
                      value={GradeOptions.find(
                        (option) => option.value === item.grade
                      )}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, index, "grade")
                      }
                      options={GradeOptions}
                      isSearchable={false}
                      classNamePrefix="react-select"
                      styles={selectStylesDD}
                      components={{
                        DropdownIndicator: () => (
                          <RxTriangleDown color="#ffffff" />
                        ),
                        IndicatorSeparator: null,
                      }}
                      placeholder="Choose"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    <Select
                      value={ResultOptions.find(
                        (option) => option.value === item.result
                      )}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, index, "result")
                      }
                      options={ResultOptions}
                      isSearchable={false}
                      classNamePrefix="react-select"
                      styles={selectStylesDD}
                      components={{
                        DropdownIndicator: () => (
                          <RxTriangleDown color="#ffffff" />
                        ),
                        IndicatorSeparator: null,
                      }}
                      placeholder="Choose"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    <Select
                      value={StatusOptions.find(
                        (option) => option.value === item.status
                      )}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, index, "status")
                      }
                      options={StatusOptions}
                      isSearchable={false}
                      classNamePrefix="react-select"
                      styles={selectStylesDD}
                      components={{
                        DropdownIndicator: () => (
                          <RxTriangleDown color="#ffffff" />
                        ),
                        IndicatorSeparator: null,
                      }}
                      placeholder="Choose"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    <input
                      className="table-input-box w-100"
                      type="date"
                      value={item.startDate}
                      onChange={(e) => handleChange(e, index, "startDate")}
                      disabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    <input
                      className="table-input-box w-100"
                      type="date"
                      value={item.endDate}
                      onChange={(e) => handleChange(e, index, "endDate")}
                      disabled={
                        !editMode[index] &&
                        manageMarks[index]?.markPercentage &&
                        manageMarks[index]?.result &&
                        manageMarks[index]?.status &&
                        manageMarks[index]?.startDate &&
                        manageMarks[index]?.endDate
                      }
                    />
                  </td>
                  <td>
                    {manageMarks[index]?.markPercentage &&
                    manageMarks[index]?.result &&
                    manageMarks[index]?.status &&
                    manageMarks[index]?.startDate &&
                    manageMarks[index]?.endDate ? (
                      <div className="manage-mark-action">
                        {!editMode[index] && (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(index)}
                          >
                            <EditIcon />
                          </span>
                        )}
                        {editMode[index] && (
                          <span
                            style={{ color: "#6fd404", cursor: "pointer" }}
                            onClick={() => {
                              handleEditSave(
                                {
                                  markId: item.markId,
                                  programFeeMasterId: item.programFeeMasterId,
                                  studentMaster: item.studentMaster,
                                  module: item.module,
                                  markPercentage: item.markPercentage,
                                  grade: item.grade,
                                  result: item.result,
                                  status: item.status,
                                  startDate: item.startDate,
                                  endDate: item.endDate,
                                },
                                true
                              );
                              setEditMode({ ...editMode, [index]: false });
                            }}
                          >
                            <FaSave />
                          </span>
                        )}
                      </div>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default StudentMarks;
