import Vector3 from "assets/images/cm/Communication Management - Circulars/Vector 3.png";
import Vector from "assets/images/cm/cm victor/Vector 3.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { CIRCULAR_URLS } from "../../constants/circular-urls";
import "./ViewCircular.css";

function Viewcircular() {
  const location = useLocation();

  const circularId = location.state?.circularId;
  const [dataa, setDataa] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (circularId) {
      axiosService
        .get(`${CIRCULAR_URLS.VIEW_CIRCULAR}/${circularId}`)
        .then((additionalResponse) => {
          const additionalData = additionalResponse?.data?.data;
          setDataa([additionalData]);
        })
        .catch((error) => {});
    }
  }, [circularId]);

  return (
    <div className="circular-main-container px-3">
      <div className="user-head-container sticky-header flex-row gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">Circulars</span>
        </div>
      </div>

      {dataa.map((data, index) => (
        <div key={index} className="mt-2">
          <p className="notice-title m-0">{data.title}</p>
          <p className="date-manage m-0">
            {new Date(data.addedOn).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </p>

          <p
            className="circular-manage-back m-0"
            onClick={() => navigate(`/communication-management/circulars`)}
          >
            Go Back
          </p>
        </div>
      ))}

      <div className="d-flex justify-content-center w-100 mt-3">
        {dataa.map((data, index) => (
          <div className="col-12 col-lg-10">
            {!data.isRead &&
              (data.isImportant ? (
                <div className="typenotice" style={{ color: "#F7931D" }}>
                  Important Notice
                </div>
              ) : (
                <div className="typenotice" style={{ color: "#047BC1" }}>
                  Recent Notice
                </div>
              ))}

            <div key={index} className="content mt-1">
              <p className="date-notice custom-scrollbar">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />
              </p>

              {data.isImportant ? (
                <img
                  src={Vector}
                  alt="Img"
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                />
              ) : (
                <img
                  src={Vector3}
                  alt="Img"
                  style={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Viewcircular;
