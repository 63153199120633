import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { FEE_URLS } from "modules/fee-finance/constants/fee-finance-urls";
import sessionService from "services/sessionstorage.service";
import AddStudentInvoice from "../fee-plans/add-student-invoice/AddStudentInvoice";
import FeesInvoice from "modules/documents/components/enrollment-letter/feesInvoice";
import { IoInformationCircle } from "react-icons/io5";
import { FaInfo } from "react-icons/fa";

function MyInvoices() {
  const location = useLocation();
  const userData = sessionService.getUserData("userData");
  const studentId = userData?.admissionManagement?.personalData?.studentId;
  const [listInvoiceData, setListInvoiceData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [pdfData, setPdfData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  let viewOnly = true;

  function formatInvoiceType(invoiceType) {
    switch (invoiceType) {
      case "TUITIONFEE":
        return "Tuition Fee";
      case "APPLICATIONFEE":
        return "Application Fee";
      case "MATERIALFEE":
        return "Material Fee";
      case "INTERNATIONALFEE":
        return "International Fee";
      case "BANKFEE":
        return "Bank Fee";
      case "OTHERFEE":
        return "Other Fee";
      case "ESLFEE":
        return "ESL Fee";
      case "FIELDTRIPSFEE":
        return "FieldTrips Fee";

      default:
        return invoiceType;
    }
  }

  useEffect(() => {
    if (studentId) {
      axiosService
        .get(`${FEE_URLS.GET_STUDENT_INVOICE_BY_ID}${studentId}`)
        .then((response) => {
          const responseData = response?.data?.data;
          setListInvoiceData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching student invoices:", error);
        });
    }
  }, [location.pathname, studentId]);

  const handleClick = (data) => {
    setPdfData(data);
    setShowInvoice(true);
  };

  const handleCloseInvoice = () => {
    setShowInvoice(false);
  };
  const navigate = useNavigate();
  const handlePayment = (value) => {
    navigate("/payment-erp", { state: value });
  };
  const columnDefs = useMemo(
    () => [
      {
        headerName: "Invoice ID",
        field: "invoiceNumber",
        width: 140,
        cellRenderer: (params) => {
          return (
            <div>
              {params.value}
              <span className="info-icon-ag-grid">
                <IoInformationCircle />
              </span>
            </div>
          );
        },
      },
      {
        headerName: "Student Name",
        valueGetter: (params) =>
          params.data?.master?.admissionManagement?.personalData?.firstName ||
          "-",
      },

      {
        headerName: "Invoice Type",
        field: "invoiceType",
        valueFormatter: function (params) {
          return formatInvoiceType(params.value);
        },
      },
      {
        headerName: "Invoice Amount",
        field: "invoiceAmount",
        width: 170,
      },
      {
        headerName: "Status",
        field: "status",
        width: 130,
        cellRenderer: function (params) {
          const status = params.value;

          return (
            <span
              className={`${
                status === "Paid" && "ag-grid-application-status-approved"
              }
            ${
              status === "Past due date" &&
              "ag-grid-application-status-rejected"
            }
            ${status === "Pending" && "ag-grid-application-status-pending"}
            ${status === "Refund Issued" && "ag-grid-application-status-refund"}
            ${
              status === "Cheque Bounce" && "ag-grid-application-status-pending"
            }`}
            >
              {status}
            </span>
          );
        },
      },

      {
        headerName: "Start Date",
        field: "startDate",
        width: 130,
      },
      {
        headerName: "Due Date",
        field: "dueDate",
        width: 125,
      },

      {
        headerName: "Pay Online",
        field: "status",
        cellRenderer: (params) => {
          let displayText;

          if (params.value === "Pending") {
            displayText = "Click to Pay";
          } else if (params.value === "Paid") {
            displayText = "Paid";
          } else if (params.value === "Past due date") {
            displayText = "Past due date";
          } else if (params.value === "Refund Issued") {
            displayText = "Refund Issued";
          } else if (params.value === "Cheque Bounce") {
            displayText = "Cheque Bounce";
          }

          return (
            <div>
              <span>{displayText}</span>
            </div>
          );
        },
      },
      {
        headerName: "Mode of Payment",
        field: "paymentMode",
      },
    ],
    []
  );
  const onCellClicked = (params) => {
    const { data, colDef } = params;
    const displayText = getDisplayTextForField(data.status);

    if (colDef.headerName === "Invoice ID") {
      handleClick(data);
    } else if (
      colDef.headerName === "Pay Online" &&
      (displayText === "Click to Pay" || displayText === "Past due date")
    ) {
      handlePayment(data);
    } else {
      setIsModalOpen(true);
      setInvoiceData(data);
    }
  };

  const getDisplayTextForField = (status) => {
    switch (status) {
      case "Pending":
        return "Click to Pay";
      case "Paid":
        return "Paid";
      case "Past due date":
        return "Past due date";
      case "Refund Issued":
        return "Refund Issued";
      case "Cheque Bounce":
        return "Cheque Bounce";
      default:
        return "";
    }
  };

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div className="view-leads-table-container">
      <div className="lead-table-head-container flex-sm-row gap-2 ms-2">
        <div className="d-flex align-items-center gap-1 ">
          <span className="lead-table-title-text">My Invoices</span>
        </div>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={listInvoiceData}
          rowSelection="multiple"
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          gridOptions={gridOptions}
          // onRowClicked={onRowClicked}
          onCellClicked={onCellClicked}
        />
        <div>
          <span className="upload-files-note" style={{ fontSize: "12px" }}>
            Note:{" "}
          </span>{" "}
          To download the Fee Invoices, please click on the{" "}
          <IoInformationCircle /> provided in the table.
        </div>
      </div>
      <AddStudentInvoice
        open={isModalOpen}
        onClose={() => {
          setInvoiceData(null);
          setIsModalOpen(false);
          axiosService
            .get(`${FEE_URLS.GET_STUDENT_INVOICE_BY_ID}${studentId}`)
            .then((response) => {
              const data = response?.data?.data;
              setListInvoiceData(data);
            })
            .catch(() => {});
        }}
        invoiceData={invoiceData}
        viewOnly={viewOnly}
      />
      <div style={{ position: "absolute", left: -9999 }}>
        {showInvoice && (
          <FeesInvoice
            downloadType={"download"}
            pdfData={pdfData}
            close={handleCloseInvoice}
          />
        )}
      </div>
    </div>
  );
}

export default MyInvoices;
