import { AppConfig } from "env.development";

export const CIRCULAR_URLS = {
  LISTCREATE_CIRCULAR: `${AppConfig.api_url}circular/v1/all`,
  GET_ALL_STAFF_IDS: `${AppConfig.api_url}user/v1/get-user/all`,
  GET_ALL_STUDENT_IDS: `${AppConfig.api_url}user/v2/get-user/all`,
  GET_ALL_USER_IDS: `${AppConfig.api_url}user/v1/getAll`,
  VIEW_CIRCULAR: `${AppConfig.api_url}circular/v1`,
  DELETE_CIRCULAR: `${AppConfig.api_url}circular/v1/`,
  GET_ALL_STUDENTS: `${AppConfig.api_url}circular/v1/get-students`,
  // GET_NOTIFICATION_BY_USER_ID: `${AppConfig.api_url}circular/v1/notification/`,
  UPDATE_CIRCULAR_NOTIFICATION: `${AppConfig.api_url}circular/v1/update-notification/`,
  GET_NOTIFICATION_BY_USER_ID: `${AppConfig.api_url}user/v1/notification-history/all/`,
  UPDATE_READ_NOTIFICATION_BY_ID: `${AppConfig.api_url}user/v1/update-notification/`,
};
