import html2canvas from "html2canvas";
import { useEffect, useRef } from "react";
import "./ActivePolicyContract.css";
import { PDFDocument } from "pdf-lib";

const ActivePolicyContract = ({ data, onClose, setActivePolicyPdfData }) => {
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;

  // const url = `${DOCUMENT_URLS.QR_CODE_URL}=${hashValueData}`;

  const activediv1Ref = useRef(null);
  const activediv2Ref = useRef(null);
  const activediv3Ref = useRef(null);

  const captureDivsToPDFBlob = async () => {
    const divs = [
      activediv1Ref.current,
      activediv2Ref.current,
      activediv3Ref.current,
    ];
    const canvases = await Promise.all(divs.map((div) => html2canvas(div)));

    const pdfDoc = await PDFDocument.create();

    for (const canvas of canvases) {
      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);
      const { width, height } = img.scale(0.5);

      const page = pdfDoc.addPage([width, height]);

      page.drawImage(img, {
        x: 0,
        y: 0,
        width: width,
        height: height,
      });
    }

    // Serialize the PDFDocument to bytes
    let pdfBytes = await pdfDoc.save();

    // Compress PDF if it's larger than 2MB
    while (pdfBytes.length > 2 * 1024 * 1024) {
      const compressedPdfDoc = await PDFDocument.load(pdfBytes);
      const tempDoc = await PDFDocument.create();
      const scale = Math.sqrt((2 * 1024 * 1024) / pdfBytes.length);

      for (let i = 0; i < compressedPdfDoc.getPageCount(); i++) {
        const [oldPage] = await tempDoc.copyPages(compressedPdfDoc, [i]);
        const { width, height } = oldPage.getSize();
        const newWidth = width * scale;
        const newHeight = height * scale;
        const newPage = tempDoc.addPage([newWidth, newHeight]);
        newPage.drawPage(oldPage, {
          x: 0,
          y: 0,
          width: newWidth,
          height: newHeight,
        });
      }

      pdfBytes = await tempDoc.save();
    }

    // Create a Blob and File from the compressed PDF
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const docFile = new File([pdfBlob], "document.pdf", {
      type: "application/pdf",
    });

    setActivePolicyPdfData(docFile);
    onClose();
  };

  useEffect(() => {
    captureDivsToPDFBlob();
  }, [data]);

  return (
    <div style={{ backgroundColor: "#212226" }}>
      <div id="active-policy-contract" style={{ color: "black" }}>
        <div
          id="active-policy-contract-context-1"
          ref={activediv1Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
            padding: "50px",
          }}
        >
          <div>
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              ACTIVE PARTICIPATION POLICY & SATISFACTORY ACADEMIC STANDING
            </h3>
            <p style={{ fontSize: "14px" }}>
              I understand that I am expected to meet the College requirements
              for active participation and academic standing. The details of the
              active participation policy and the academic requirements can be
              found in the Student Handbook.
            </p>
            <p style={{ fontSize: "14px" }}>
              I understand that my funding may be affected if my grades are
              unsatisfactory or if I do not meet the active participation
              requirements. I understand that I will not receive my loan(s)
              unless I am in good standing (
              <i>
                I complete all approved program of study course requirements by
                my approved study period end date, including fulfilling all
                course requirements that I missed or failed due to absence
              </i>
              ) and in full time attendance (
              <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                I attends all scheduled classes for the Approved Program and
                receive a minimum of 80 hours of Computer Based Learning (i.e.,
                ILS Instruction) over any period of 28 calendar days
              </span>
              ). I further understand that if I do not comply with either the
              active participation or academic progress policy (or both), the
              College must report my status to the Ministry. In this event, it
              will be considered a withdrawal for OSAP purposes, and I will be
              ineligible for further OSAP funding.
            </p>
            <p style={{ fontSize: "14px" }}>
              I understand that the unused portion of my bursary and/or loan
              document(s) will be returned to the Ministry in the event that my
              performance is unsatisfactory, or I stop attending classes on a
              full-time basis.
            </p>
            <p style={{ fontSize: "14px" }}>
              I understand that course requirements for the approved program of
              study that I missed or failed to fulfil due to absence are noted
              in my Student File, and my file is subject to audit by the
              Ministry of Colleges and Universities.
            </p>
            <p style={{ fontSize: "16px" }}>REPORT CHANGES OF STATUS</p>
            <p style={{ fontSize: "14px" }}>
              I must inform the Financial Aid Administrator at my campus if:
            </p>
            <ul style={{ fontSize: "14px" }}>
              <li>
                my martial status changes: separation, divorce, marriage, start
                or end of a common law relationship,
              </li>
              <li>
                my family circumstances change: birth of a child, change in
                custody,
              </li>
              <li>
                my income (from any source) changes: increase or decrease, loss
                of job, obtain a job,
              </li>
              <li>the income orstatus of my parent's changes,</li>
              <li>the income orstatus of my spouse changes,</li>
              <li>
                I change my program of studies: course, length, hours, orswitch
                to part-time studies,
              </li>
              <li>my address and/or phone number changes.</li>
            </ul>
          </div>
        </div>
        <div
          id="active-policy-contract-context-2"
          ref={activediv2Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
            padding: "50px",
          }}
        >
          <div>
            <h3 style={{ fontSize: "16px" }}>CHANGES IN MY STUDY PERIOD</h3>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>Start Date</p>
            <p style={{ fontSize: "14px" }}>
              I understand that if my start date is postponed, I must notify my
              Financial Aid Administrator so that my file can be updated. If the
              date changes by more than 30 days, the College must return any
              documents on file to the Ministry so that they can be reissued.
            </p>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>End Date</p>
            <p style={{ fontSize: "14px" }}>
              I understand that if for any reason my course end date is extended
              beyond the original end date as shown on my loan document, my
              interest charges start the month following the original end date.
              I will have to start paying my loan(s)six months after my original
              end date.
            </p>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>
              Study Breaks
            </p>
            <p style={{ fontSize: "14px" }}>
              If for any reason there is a break in my studies that lasts for 28
              days or more, I understand that the College must report this break
              to the Ministry and that it is considered a withdrawal under OSAP.
              In the event that I do not return from any break within the same
              academic year that I started my studies, this will be considered a
              withdrawal for OSAP purposes. Should this situation occur, I will
              need to reapply for OSAP funding and meet all eligibility
              requirements in order to secure funding for the balance of my
              program. If there are less than twelve weeks remaining in my
              studies aftersuch a withdrawal, I will not be eligible for further
              assistance.
            </p>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>
              Change in Program
            </p>
            <p style={{ fontSize: "14px" }}>
              If I change my program after having started classes I understand
              that under OSAP, this is considered a withdrawal from my original
              program.
            </p>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>
              Schedule 2 and/or Continuation of Interest Free Status Form
            </p>

            <ul style={{ fontSize: "14px" }}>
              <li>
                If I have had a study break or had to repeat a module, I
                understand that a Schedule 2 and/or Continuation of Interest
                Free Status Form are needed to extend my interest free status. I
                must be in full time attendance and my situation must meet the
                following:
              </li>
              <ul>
                <li>
                  I had a medical reason and obtained permission for a medical
                  leave. I understand that I may be asked to provide a medical
                  note and that my loan entitlement may be affected as a result.
                </li>
                <li>
                  I had special circumstances and obtained permission for a
                  leave of absence. I understand that my loan entitlement may be
                  affected as a result.
                </li>
              </ul>
            </ul>
            <p style={{ fontSize: "14px" }}>
              I understand it is my responsibility to obtain, if eligible, a
              Schedule 2 and/or a Continuation of Interest Free Status Form,
              when I return to full time studies.
            </p>
          </div>
        </div>
        <div
          id="active-policy-contract-context-3"
          ref={activediv3Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
            padding: "50px",
          }}
        >
          <div>
            <p style={{ fontSize: "14px", fontStyle: "italic" }}>Withdrawal</p>
            <ul style={{ fontSize: "14px", marginBottom: "20px" }}>
              <li>
                I understand that it is my responsibility to inform the College
                in writing when I am unable to attend classes or complete my
                course and that the College must report this to the Ministry.
              </li>
              <li>
                I understand that if I am absent for 28 consecutive days or
                more, the College must report these absences to the Ministry.
                These absences are considered to be a withdrawal for OSAP
                purposes, and the College must return any un-negotiated loans to
                the Ministry. I understand that this type ofsituation will
                affect my standing with the College and as well, may result in
                an overpayment as it may affect the amount of funding I am
                entitled to, the total amount to be repaid and the time during
                which I will have my loan in interest free status. This
                situation may also affect the approval of future OSAP loan
                applications.
              </li>
              <li>
                I understand that I am responsible for all loans released to me
                and if I am entitled to a refund, such refund will be paid to
                the National Student Loans Service Centre.
              </li>
              <li>
                I am responsible for and must repay all loans released to me
                even if I do not complete or graduate from my program.
              </li>
            </ul>
            <p style={{ fontSize: "16px" }}>
              IT IS MY RESPONSIBILITY TO NOTIFY THE COLLEGE OF MY RETURN TO
              CLASS.
            </p>
            <p style={{ fontSize: "14px" }}>
              Depending on the situation, I may need to reapply for OSAP for the
              remaining weeks of my course.
            </p>
            <p style={{ fontSize: "16px" }}>ACKNOWLEDGEMENT OF RECEIPT</p>
            <p style={{ fontSize: "14px" }}>
              I confirm that I have read, understood, and received a copy of
              this document.
            </p>
            <div
              style={{
                width: "50%",
                borderBottom: "1px solid black",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              {data?.personalData?.firstName} {data?.personalData?.lastName}
            </div>
            <div style={{ width: "50%", fontSize: "14px" }}>
              Student Printed Name
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "40px",
              }}
            >
              <div
                style={{ width: "50%", borderBottom: "1px solid black" }}
              ></div>
              <div
                style={{
                  width: "40%",
                  borderBottom: "1px solid black",
                  textAlign: "center",
                }}
              >
                {formattedDate}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%", fontSize: "14px" }}>
                Student Signature
              </div>
              <div style={{ width: "40%", fontSize: "14px" }}>Date</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivePolicyContract;
