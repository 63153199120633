import betalogo from "assets/images/icons/beta.svg";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import "./AgreementLetter.css";
import beta from "assets/images/Beta_New_Header.png";
import footer from "assets/images/Beta_New_Footer.png";
import directorSign from "assets/images/agreement-letter/Director.png";
import { axiosService } from "services/axios.service";
import { QRCode } from "react-qr-code";
import { toast } from "react-toastify";
import { DOCUSIGN_URL } from "modules/my-profile/constants/docusign-urls";
import { PDFDocument } from "pdf-lib";

const PdfContent = ({ data }) => {
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  let formattedDate = `${day}/${month}/${year}`;
  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}/${month}/${day}`;
  // };
  const Header = () => (
    <div style={{}}>
      <img alt="img" src={beta} style={{ width: "100%", height: "100px" }} />
    </div>
  );

  const Footer = () => (
    <div
      style={{
        top: "10px",
        left: "0px",
        right: "5px",
        bottom: "20px",
      }}
    >
      <img
        alt="img"
        src={footer}
        style={{
          width: "100%",
          height: "80px",
        }}
      />
    </div>
  );

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);

  useEffect(() => {
    captureDivsToPDFBlob();
  }, []);
  const captureDivsToPDFBlob = async () => {
    const content = document.getElementById("contract-pdf-content");
    content.classList.remove("student-id-card-hidden");
    let recipientEmail = data?.email;
    let recipientName = data?.employeeFirstName;
    const divs = [div1Ref.current, div2Ref.current, div3Ref.current];
    const canvases = await Promise.all(divs.map((div) => html2canvas(div)));

    const pdfDoc = await PDFDocument.create();

    for (const canvas of canvases) {
      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);
      const { width, height } = img.scale(0.5);

      const page = pdfDoc.addPage([width, height]);

      page.drawImage(img, {
        x: 0,
        y: 0,
        width: width,
        height: height,
      });
    }

    let pdfBytes = await pdfDoc.save();

    while (pdfBytes.length > 2 * 1024 * 1024) {
      const compressedPdfDoc = await PDFDocument.load(pdfBytes);
      const tempDoc = await PDFDocument.create();
      const scale = Math.sqrt((2 * 1024 * 1024) / pdfBytes.length);

      for (let i = 0; i < compressedPdfDoc.getPageCount(); i++) {
        const [oldPage] = await tempDoc.copyPages(compressedPdfDoc, [i]);
        const { width, height } = oldPage.getSize();
        const newWidth = width * scale;
        const newHeight = height * scale;
        const newPage = tempDoc.addPage([newWidth, newHeight]);
        newPage.drawPage(oldPage, {
          x: 0,
          y: 0,
          width: newWidth,
          height: newHeight,
        });
      }

      pdfBytes = await tempDoc.save();
    }
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const docFile = new File([pdfBlob], "document.pdf", {
      type: "application/pdf",
    });

    if (docFile) {
      const response = await axiosService.postFile2(
        `${DOCUSIGN_URL.SEND_ENVELOPE_FOR_AGREEMENT}`,

        recipientName,
        recipientEmail,
        docFile
      );

      if (response.status === 200) {
        window.location.href = response?.data;
      } else {
        toast.error("Failed to send offer letter");
      }
    }
    // const url = URL.createObjectURL(pdfBlob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "divs.pdf";
    // document.body.appendChild(a);
    // a.click();
    // URL.revokeObjectURL(url);
    // content.classList.add("student-id-card-hidden");
  };

  return (
    <div style={{ backgroundColor: "#212226" }}>
      <div>
        {data?.agreementLetter && data?.agreementLetterName && (
          <div>
            <a href={data?.agreementLetter} download>
              {data?.agreementLetterName}
            </a>
          </div>
        )}
      </div>

      <div id="contract-pdf-content" className="student-id-card-hidden">
        <br />
        <br />
        <div
          className="contract-text-content"
          id="text-content contract-context-1"
          ref={div1Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <div id="header-container" className="header-ts">
            <Header />
          </div>
          <div>
            <h4>Employment Contract</h4>
            <h3>THIS EMPLOYMENT CONTRACT</h3>
            <p className="contract-text-p-center">
              (The “Agreement”) dated this{" "}
              <span
                style={{
                  textDecoration: "underline",
                  padding: "0px 5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {formattedDate}{" "}
              </span>
              (for the class of 2024){" "}
            </p>
            <p className="contract-text-p-center-bold">
              Between: <br />
              Beta College of Business and Technology
              <br />
              (the “Employer”) OF THE FIRST PART
              <br />
              and{" "}
            </p>
            <p className="contract-text-p-center">
              <span
                style={{
                  textDecoration: "underline",
                  padding: "0px 5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {data?.employeeFirstName}{" "}
                {data?.employeeLastName ? data?.employeeLastName : ""}{" "}
              </span>
            </p>
            <p className="contract-text-p-center">(The Employee)</p>
            <p className="contract-text-p-center-bold">OF THE SECOND PART</p>

            <h3 className="contract-text-h3-left-bold">BACKGROUND: </h3>
            <ol style={{ listStyleType: "upper-alpha" }}>
              <li>
                The Employer is of the opinion that the employee has the
                necessary qualifications, experience and abilities to assist and
                benefit the employer in its business.
              </li>
              <li>
                Employer desires to employ the Employee and the Employee has
                agreed to accept and enter such employment upon the terms and
                conditions set out in this agreement.
                <br />
                <br />
                In consideration of the matters described above and of the
                mutual benefits and obligations set forth in this agreement, the
                receipt and sufficiency of which consideration is hereby
                acknowledged, the parties to this agreement agree as follows:
              </li>
            </ol>
            <h3 className="contract-text-h3-left-bold">
              COMMENCEMENT DATE AND TERM:
            </h3>
            <ol>
              <li>
                The Employee commenced the employment with the Employer on the
              </li>
              <li>
                Subject to termination as provided in this Agreement, the
                Employee's position is a temporary position and will end on
                completion of the discussed modules to be taught.
              </li>
            </ol>
            <h3 className="contract-text-h3-left-bold">
              JOB TITLE AND DESCRIPTION:
            </h3>
            <ol start="3">
              <li>
                The initial job title of the Employee will be the following:
                <br />
                Faculty for
                <span
                  style={{
                    textDecoration: "underline",
                    padding: "0px 5px",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {data?.position}{" "}
                </span>
              </li>
              <li>
                The Employee agrees to be employed on the terms and conditions
                set out in this agreement.
              </li>
              <li>
                The Employee will perform any and all duties as requested by the
                Employer that are reasonable and that are customarily performed
                holding a similar position in the industry or business of the
                Employer.
              </li>
              <li>
                The Employee agrees to abide by the Employer's rules,
                regulations, policies and practices, including those concerning
                work, vacation and sick leaves as they may from time to time be
                adopted or modified{" "}
              </li>
            </ol>

            <h3 className="contract-text-h3-left-bold">
              EMPLOYEE COMPENSATION:{" "}
            </h3>
            <ol start="7">
              <li>
                The salary is effective from ___________________ Compensation
                paid to the Employee for the services rendered by the Employee
                as required by this Agreement (the “Compensation”) will be a
                CAD________ /hr.
              </li>
            </ol>
            <h3 className="contract-text-h3-left-bold">PLACE OF WORK: </h3>
            <ol start="8">
              <li>
                The Employee's primary place of work will be at one of the
                following locations for hybrid or online:
                <br />
                Brampton Campus: 1 Nelson Street West, Suite 200A, Brampton ON,
                L6X 3E4, Canada.
                <br />
                Mississauga Campus: 4275 Village Centre Court, Suite 201,
                Mississauga ON, L4Z 1V3, Canada.{" "}
              </li>
            </ol>
          </div>

          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: "20px",
              right: "0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "8px",
                left: "33px",
                zIndex: 1,
              }}
            >
              <QRCode
                size={70}
                // value={url}

                value="beta College"
              />
            </div>
            {/* )} */}

            <Footer />
          </div>
        </div>
        <div
          className="contract-text-content"
          id="text-content contract-context-2"
          ref={div2Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <div id="header-container" className="header-ts">
            <Header />
          </div>
          <div>
            <h3 className="contract-text-h3-left-bold">
              CONFLICT OF INTEREST:{" "}
            </h3>
            <ol start="9">
              <li>
                During the term of the Employee's active employment with the
                Employer, it is understood and agreed that any business
                opportunity relating to or similar to the Employer's actual or
                reasonably anticipated business opportunities, coming to the
                attention of the Employee is an opportunity belonging to the
                Employer. Therefore, the Employer will advise the Employer of
                the opportunity and cannot pursue the opportunity, directly or
                indirectly without the consent of the Employer.
              </li>
            </ol>
            <p style={{ fontSize: "12px" }}>
              Subject to reasonable direction by the Employer, the Employee
              shall perform the following duties and have the following
              responsibilities:{" "}
            </p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                Ensure programs, support services, and education are performed
                in accordance with Ministry policy and practice standards.
              </li>
              <li>
                Maintains effective relationships with Staff and students with
                diverse priorities.{" "}
              </li>
            </ol>
            <p>
              However, it is understood and agreed that these duties and
              responsibilities are not exhaustive and may be changed with the
              Employer's changing circumstances; in this regard, the Employee
              may not refuse any reasonable employment request by the Employer.{" "}
            </p>
            <p>
              Subject to statutory holidays and ordinary vacation, the Employee
              shall work the required needed to complete the academic
              commitments like attendance, evaluations, and study materials.
              above-mentioned modules.{" "}
            </p>

            <p>
              It is understood and agreed that any ordinary vacation is subject
              to reasonable control.{" "}
            </p>
            <ol type="a" start="3">
              <li>
                The Employer may terminate the employment of the Employee
                without cause on two weeks' notice to the Employee. However, the
                Employer reserves the right to terminate the Employee's
                employment without notice for cause, including but not limited
                to death, incapacitating illness, incompetence, or failure to
                follow reasonable employment requests, at any time.{" "}
              </li>
              <li>
                If the Employee wishes to terminate this employment with the
                Employer, the Employee will provide the Employer with two weeks
                of notice period and should co-operate with the training and
                development of the replacement for the same position.{" "}
              </li>
              <li>
                Once the notice has been given by either part for any reason,
                the Employee or the Employer agree to execute their duties and
                obligations under this Agreement diligently and in good faith
                through to the end of the notice period. The Employer may not
                make any changes to the compensation or any other term or
                condition of this agreement between the time termination notice
                is given through to the end of the notice period.
              </li>
              <li>
                The Employee promises to keep the Employer's business secrets
                confidential during and after the term of employment.
              </li>
              <li>
                If any provision or part of any provision in this agreement is
                void for any reason, it shall be severed without affecting the
                validity of the balance of the agreement.{" "}
              </li>
              <li>
                Except for increases in salary or extension of employment
                benefits, the terms of this agreement remain in force until
                amended in writing and signed by both parties.
              </li>
              <li>
                There are no representations, warranties, conditions, terms, or
                collateral contracts affecting the employment contemplated in
                this agreement except as set out in this agreement.{" "}
              </li>
              <li>
                The Employee acknowledges ample opportunity and advice to take
                independent legal advice in connection with the execution of
                this employment agreement.
              </li>
            </ol>
          </div>

          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: "20px",
              right: "0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                right: "0px",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "8px",
                left: "33px",
                zIndex: 1,
              }}
            >
              <QRCode
                size={70}
                value="beta College"
                //  value={url}
              />
            </div>
            {/* )} */}

            <Footer />
          </div>
        </div>
        <div
          className="contract-text-content"
          id="text-content contract-context-3"
          ref={div3Ref}
          style={{
            width: "794px",
            height: "1123px",
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <div id="header-container" className="header-ts">
            <Header />
          </div>
          <div>
            <br />
            <p>
              This agreement is governed by the laws of the Province of Ontario.{" "}
            </p>
            <p>Executed under seal on ___________________________ </p>
            <p>Signed, sealed, and delivered in the presence of:</p>
            <p>For the Employer </p>
            <br />
            <img src={directorSign} />
            <br />
            <p>
              Richa Jaiswal
              <br />
              Director Studies
              <br />
              Beta College of Business and Technology
            </p>
            <p>For the Employee </p>
            <br />
            <br />
            <p>
              (Employee Signature)
              <br />
              Name: {data?.employeeFirstName}{" "}
              {data?.employeeLastName ? data?.employeeLastName : ""} <br />
              Date: {formattedDate}
            </p>
          </div>

          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: "20px",
              right: "0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "8px",
                left: "33px",
                zIndex: 1,
              }}
            >
              <QRCode size={70} value="beta College" />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

const AgreementLetter = ({ downloadType, data, close }) => {
  const [load, setLoad] = useState(false);

  // const [hashValueData, setHashValue] = useState(
  //     sha256(`${"Academic-Transcript"}-${studentId}`).toString().substring(0, 20)
  // );

  return (
    <span
      className="enrollment-container"
      style={{ backgroundColor: "#212226" }}
    >
      {load && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
      {(downloadType === "view" || downloadType === "download") && (
        <PdfContent includeWatermark={downloadType} data={data} />
      )}
    </span>
  );
};

export default AgreementLetter;
