import Vector3 from "assets/images/cm/Communication Management - Circulars/Vector 3.png";
import Vector from "assets/images/cm/cm victor/Vector 3.png";
import { useEffect, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import Swal from "sweetalert2";
import { CircularConstants } from "../../constants/circular-constants.js";
import { CIRCULAR_URLS } from "../../constants/circular-urls.js";
import AddCircularNotice from "../add-circular-notice/AddCircularNotice.jsx";
import "./ViewCircular.css";
import sessionService from "services/sessionstorage.service.js";

function extractPlainTextFromHTML(htmlString) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const plainText = tempDiv.textContent || tempDiv.innerText;
  tempDiv.remove();
  return plainText;
}

function Management() {
  const navigate = useNavigate();

  const [circularId, setCircularId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState([]);
  const userData = sessionService.getUserData("userData");
  const fetchData = async () => {
    if (!hasPermission("Circulars: create")) {
      try {
        const response = await axiosService.get(
          `${CIRCULAR_URLS.LISTCREATE_CIRCULAR}/${userData?.userId}`
        );

        if (response.status === 200) {
          const responseData = response.data.data;
          setData(responseData);
        } else {
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchDataForAdd = async () => {
    if (hasPermission("Circulars: create")) {
      try {
        const response = await axiosService.get(
          `${CIRCULAR_URLS.LISTCREATE_CIRCULAR}`
        );

        if (response.status === 200) {
          const responseData = response.data.data;
          setData(responseData);
        } else {
        }
      } catch (error) {}
    }
  };
  useEffect(() => {
    fetchDataForAdd();
  }, []);

  const navigateToViewCircular = (circularId) => {
    navigate(`/communication-management/view-circular`, {
      state: { circularId },
    });
  };

  const handleCicularEdit = (circularId) => {
    setCircularId(circularId);
    setIsModalOpen(true);
  };
  const upDateCirculars = async (circularId) => {
    try {
      const response = await axiosService.put(
        `${CIRCULAR_URLS.UPDATE_CIRCULAR_NOTIFICATION}${circularId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(`Error updating circular notification: ${error.message}`);
    }
  };

  const handleCicularDelete = (circularId) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this Circular?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axiosService
          .delete(`${CIRCULAR_URLS.DELETE_CIRCULAR}${circularId}`)
          .then((response) => {
            if (response.status === 200) {
              toast.success(CircularConstants.DELETE_SUCCESS);
              fetchDataForAdd();
            } else {
              toast.error(CircularConstants.DELETE_FAILURE);
            }
          })
          .catch((error) => {
            toast.error(CircularConstants.FAILED);
          });
      }
    });
  };

  function hasPermission(permission) {
    let userPermissions = sessionService?.hasPermissionByButton(permission);
    return userPermissions;
  }
  return (
    <div className="circular-main-container px-3">
      <div className="user-head-container sticky-header flex-row gap-2">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">Circulars</span>
        </div>
        <div className="d-flex align-items-center gap-3">
          {hasPermission("Circulars: create") && (
            <button
              className="user-head-button"
              style={{ background: "#f69f3a" }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add New
            </button>
          )}
        </div>
      </div>

      <div className="circulars-main-cards-container">
        {data?.map((data, index) => (
          <div
            key={index}
            className="circular-cards"
            style={
              !hasPermission("Circulars: create") && !data.isRead
                ? { borderLeft: "3px solid #f69f3a" }
                : null
            }
          >
            {data.isImportant ? (
              <img
                src={Vector}
                alt="Img"
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              />
            ) : (
              <img
                src={Vector3}
                alt="Img"
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                }}
              />
            )}

            <p className="notice-title m-0">{data?.title}</p>
            <p className="date-manage m-0 mt-2">
              {new Date(data.addedOn).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
            <p
              style={{
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                maxHeight: "3em",
                textOverflow: "ellipsis",
                display: "block",
              }}
              className="date-notice m-0"
            >
              <span
                className="date-notice-elipsis"
                dangerouslySetInnerHTML={{
                  __html: extractPlainTextFromHTML(data.description),
                }}
              />
            </p>

            <div
              className="d-flex align-center-center w-100"
              style={{
                position: "absolute",
                bottom: "15px",
                color: "#f69f3a",
                paddingRight: "30px",
                flexDirection: "column",
              }}
            >
              {!hasPermission("Circulars: create") && (
                <p
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  className="circular-manage m-0"
                  onClick={() => {
                    navigateToViewCircular(data.circularId);
                    upDateCirculars(data.latestCircularId);
                  }}
                >
                  View Circular
                </p>
              )}

              <div className="d-flex align-center-center gap-2 justify-content-end">
                {hasPermission("Circulars: edit") && (
                  <div onClick={() => handleCicularEdit(data.circularId)}>
                    <FaEdit size={20} />
                  </div>
                )}

                {hasPermission("Circulars: create") && (
                  <div onClick={() => handleCicularDelete(data.circularId)}>
                    <IoTrashOutline size={20} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <AddCircularNotice
        open={isModalOpen}
        circularId={circularId}
        onClose={() => {
          setCircularId(null);
          setIsModalOpen(false);
          fetchDataForAdd();
        }}
      />
    </div>
  );
}
export default Management;
