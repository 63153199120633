import React, { useEffect, useRef, useState } from "react";
import "./QueryFeedbackChat.css";
import sessionstorageService from "services/sessionstorage.service";
import { ImAttachment } from "react-icons/im";
import { IoMdSend } from "react-icons/io";
import { QUERY_FEEDBACK_URLS } from "../constants/query-feedback-urls";
import { axiosService } from "services/axios.service";
import { RiCloseCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { NEW_CONVERSATION_CONSTANTS } from "../constants/query-feedback-constants";
import useravatar from "assets/images/user-avatar.svg";
import { FaFile } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

function QueryFeedbackChatMobile() {
  const location = useLocation();
  const chatId = location?.state?.chatId;

  const [chatData, setChatData] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [sendMessageText, setSendMessageText] = useState(null);
  const [closedUpdateRefresh, setClosedUpdateRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const [attachment, setAttachment] = useState(null);
  const [attachmentFileName, setAttachmentFileName] = useState(null);

  const userData = sessionstorageService.getUserData("userData");

  function hasPermission(permission) {
    let userPermissions =
      sessionstorageService.hasPermissionByButton(permission);
    return userPermissions;
  }

  const scrollContainerRef = useRef(null);
  const messageContainerRef = useRef(null);

  const scrollBottom = () => {
    const container = scrollContainerRef.current;
    const messager = scrollContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight + messager.scrollHeight;
    }
  };

  const fetchData = async () => {
    const response = await axiosService.get(
      `${QUERY_FEEDBACK_URLS.GET_CHAT_BY_CHAT_ID}${chatId}`
    );
    const data = response?.data?.data;
    setChatData(response?.data?.data);
    if (data?.receiver?.userId !== userData?.userId) {
      setReceiver(data?.receiver);
    } else {
      setReceiver(data?.sender);
    }
    scrollBottom();
  };

  useEffect(() => {
    if (chatData) {
      scrollBottom();
    }
  }, [chatData]);

  useEffect(() => {
    calculateTextareaHeight();
  }, [sendMessageText]);

  useEffect(() => {
    setAttachment(null);
    setAttachmentFileName(null);
    setSendMessageText("");
    const fetchDataAndUpdate = async () => {
      if (chatId) {
        const response = await axiosService.getByTime(
          `${QUERY_FEEDBACK_URLS.GET_CHAT_BY_CHAT_ID}${chatId}`
        );
        const data = response?.data?.data;

        if (
          chatData === null ||
          chatData?.conversation?.length != data?.conversation?.length
        ) {
          setChatData(data);
          scrollBottom();
          if (data?.receiver?.userId !== userData?.userId) {
            setReceiver(data?.receiver);
          } else {
            setReceiver(data?.sender);
          }
        }
      }
    };
    fetchDataAndUpdate();
    const intervalId = setInterval(fetchDataAndUpdate, 1000);
    return () => clearInterval(intervalId);
  }, [chatId, userData?.userId, chatData]);

  const SendMessage = async () => {
    if (sendMessageText !== "") {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const conversation = {
        senderId: userData?.userId,
        senderName:
          userData?.firstName +
          " " +
          (userData?.lastName ? userData?.lastName : ""),
        message: sendMessageText,
        date: formattedDate,
      };
      let sendData = {
        chatId: chatId,
        chatConversation: conversation,
      };
      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.SEND_MESSAGE}`,
        sendData
      );
      if (response.status === 200) {
        setSendMessageText("");
        fetchData();
      }
    } else if (attachment) {
      setLoading(true);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const conversation = {
        senderId: userData?.userId,
        senderName:
          userData?.firstName +
          " " +
          (userData?.lastName ? userData?.lastName : ""),
        message: `<a target="_blank" rel="noreferrer" href=${attachment}> ${attachmentFileName}</a>`,
        date: formattedDate,
      };
      let sendData = {
        chatId: chatId,
        chatConversation: conversation,
      };

      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.SEND_MESSAGE}`,
        sendData
      );
      if (response.status === 200) {
        setSendMessageText("");
        setAttachment(null);
        setAttachmentFileName(null);
        fetchData();

        setLoading(false);
      } else {
        toast.error(response.error.message);
        setLoading(false);
      }
    }
  };
  function formatDateString(inputDateString) {
    const date = new Date(inputDateString);

    const dateOptions = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);

    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    return `${formattedDate} - ${formattedTime}`;
  }
  const closeChat = async () => {
    if (chatId) {
      const response = await axiosService.put(
        `${QUERY_FEEDBACK_URLS.CLOSE_CHAT}${chatId}`
      );
      if (response.status === 200) {
        setClosedUpdateRefresh(!closedUpdateRefresh);
        toast.success(NEW_CONVERSATION_CONSTANTS.CLOSED_SUCCESS);
        navigate(-1);
      } else {
        toast.error(response.data.message);
      }
    }
  };
  const attachFile = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setSendMessageText("");
    const maxSizeInBytes = 2 * 1024 * 1024;

    if (file?.size > maxSizeInBytes) {
      toast.error(NEW_CONVERSATION_CONSTANTS.MAX_FILE_SIZE);
      setLoading(false);
    } else {
      try {
        const response = await axiosService.postFile(
          `${QUERY_FEEDBACK_URLS.ATTACHMENT_FILE_UPLOAD}`,
          file,
          true
        );
        if (response.status === 200) {
          setAttachmentFileName(file?.name);
          const data = response.data.data;
          setAttachment(data);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message.split(":")[1]);
        setLoading(false);
      }
    }
  };
  const handleDrop = async (event) => {
    setIsDraggingOver(false);
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setLoading(true);
    setSendMessageText("");

    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      toast.error(NEW_CONVERSATION_CONSTANTS.MAX_FILE_SIZE);
      setLoading(false);
    } else {
      try {
        const response = await axiosService.postFile(
          `${QUERY_FEEDBACK_URLS.ATTACHMENT_FILE_UPLOAD}`,
          file,
          true
        );
        if (response.status === 200) {
          const data = response.data.data;
          setAttachment(data);
          setLoading(false);
          setAttachmentFileName(file?.name);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.response.data.message.split(":")[1]);
        setLoading(false);
      }
    }
  };

  const dateObject = new Date(chatData?.updatedOn);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };

  const formattedDate = dateObject?.toLocaleString("en-US", options);

  const calculateTextareaHeight = () => {
    const element = document.getElementById("query-chatlist-message-input");
    if (element) {
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;
    }
  };
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <div className="communication-management-head-container flex-row gap-2 px-2">
        <div className="d-flex align-items-center gap-3 ">
          <span className="communication-management-title-text">
            Query/Feedback
          </span>
        </div>
        {hasPermission("QueriesFeedbackChat: delete") && chatData?.isOpen && (
          <button className="mark-as-closed-button" onClick={() => closeChat()}>
            Mark as closed
          </button>
        )}
      </div>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDraggingOver(true);
        }}
        onDragLeave={() => {
          setIsDraggingOver(false);
        }}
        ref={scrollContainerRef}
        className="query-feedback-right-container w-100 custom-scrollbar p-0 col-md-8 col-lg-9 position-relative"
      >
        {isDraggingOver ? (
          <div className="drop-here-container">
            <FaFile /> Drop an file here
          </div>
        ) : chatId ? (
          <>
            <div className="profile-container-query-chat-screen p-2 gap-1 flex-column justify-content-start sticky-header-communication">
              <button
                className="all-conversation-chat-text"
                onClick={() => navigate(-1)}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.04754 13.0623L7.35387 13.774C7.06015 14.0753 6.5852 14.0753 6.29461 13.774L0.220288 7.545C-0.0734293 7.24365 -0.0734293 6.75635 0.220288 6.45821L6.29461 0.226013C6.58833 -0.0753378 7.06327 -0.0753378 7.35387 0.226013L8.04754 0.937715C8.34438 1.24227 8.33813 1.73918 8.03504 2.03733L4.26984 5.71766H13.2501C13.6657 5.71766 14 6.06068 14 6.48706V7.51294C14 7.93932 13.6657 8.28234 13.2501 8.28234H4.26984L8.03504 11.9627C8.34126 12.2608 8.34751 12.7577 8.04754 13.0623Z"
                    fill="white"
                  />
                </svg>
                all conversations
              </button>

              <div className="d-flex align-items-center gap-3 justify-content-start">
                <img
                  alt="img"
                  src={receiver?.profileImage || useravatar}
                  className="profile-image-query-chat-screen-mobile"
                />
                <div className="flex flex-column">
                  <div className="d-flex flex-column justify-content-center">
                    <span className="profile-name-text-query-chat-screen">
                      {receiver?.firstName} {receiver?.lastName}
                    </span>
                    <span className="profile-student-id-text-query-chat-screen">
                      {receiver?.studentId ? "@" + receiver?.studentId : ""}
                    </span>
                  </div>
                  <span className="chat-description-text-query-chat-screen">
                    {chatData?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="query-chat-container w-100 p-2">
              {chatData?.conversation?.map((message) => (
                <div
                  className={`${
                    message.senderId === userData?.userId
                      ? "send-message-text-container"
                      : "receive-message-text-container"
                  }`}
                >
                  <div
                    ref={messageContainerRef}
                    className="send-receive-message-text-inner-container"
                  >
                    {message.message
                      ?.trim()
                      ?.startsWith(
                        '<a target="_blank" rel="noreferrer" href='
                      ) ? (
                      <span
                        className="send-receive-message-text"
                        style={
                          message.senderId !== userData?.userId
                            ? { background: "#1e273f", border: "none" }
                            : {}
                        }
                      >
                        Uploaded File :{"  "}
                        <ImAttachment color="#F69F3A" />
                        <span
                          className="attachment-color-query"
                          dangerouslySetInnerHTML={{
                            __html: message.message,
                          }}
                        />
                      </span>
                    ) : (
                      <span
                        className="send-receive-message-text"
                        style={
                          message.senderId !== userData?.userId
                            ? { background: "#1e273f", border: "none" }
                            : {}
                        }
                      >
                        {message.message}
                      </span>
                    )}
                    <span className="send-receive-message-text-date">
                      by{" "}
                      <span className="send-receive-message-text-date-underline">
                        {message?.senderName}
                      </span>{" "}
                      on {formatDateString(message?.date)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {chatData?.isOpen ? (
              <div className="w-100 w-lg-90 query-chatlist-message-input-container gap-3 p-2">
                <textarea
                  id="query-chatlist-message-input"
                  className="query-chatlist-message-input custom-scrollbar"
                  placeholder={`${attachmentFileName ? "" : "Type a message"}`}
                  disabled={attachmentFileName}
                  value={sendMessageText}
                  onChange={(event) => {
                    setSendMessageText(event.target.value);
                  }}
                />
                {attachmentFileName && (
                  <span className="attachement-text-container">
                    <span className="close-attachment-icon">
                      <RiCloseCircleFill
                        onClick={() => {
                          setAttachment(null);
                          setAttachmentFileName(null);
                        }}
                      />
                    </span>
                    <a
                      href={attachment}
                      target="_blank"
                      rel="noreferrer"
                      className="attachement-text"
                    >
                      {attachmentFileName}
                    </a>
                  </span>
                )}
                <label
                  htmlFor="fileInput"
                  className="query-chatlist-message-attach-input-icon"
                >
                  <ImAttachment color="#F69F3A" />
                </label>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={attachFile}
                />

                <button
                  className="chat-message-input-send-button background-primary"
                  onClick={() => SendMessage()}
                  disabled={loading || (!sendMessageText && !attachment)}
                >
                  {loading ? (
                    <div
                      class="lds-ring"
                      style={{ width: "10px", height: "10px" }}
                    >
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <IoMdSend color="#F7931D" size={"25px"} />
                  )}
                </button>
              </div>
            ) : (
              <div className="w-100 w-lg-90 query-chatlist-message-input-container gap-3 py-2">
                <span className="feedback-closed-on-text">
                  Conversation closed on {formattedDate}
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="no-chat-text"> No chat to display</div>
        )}
      </div>
    </>
  );
}

export default QueryFeedbackChatMobile;
