import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./OfferLetter.css";

const PdfContent = ({ studentName }) => {
  const containerRef = useRef(null);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const currentDate = new Date();

  return (
    <div
      style={{
        color: "#000000",
        fontSize: "14px",
      }}
    >
      <div
        className="text-content-consent"
        id="pdf-container"
        ref={containerRef}
      >
        <strong>
          [Company Letterhead]
          <br />
          [Date]
          <br />
          <br />
          [Candidate’s Name]
          <br />
          [Candidate’s Address]
          <br />
          <br />
          Dear [Candidate’s Name],
        </strong>
        <br />
        <br />
        <p>
          We are excited to offer you the position of [Job Title] at [Company
          Name].
          <br /> Below are the key details of the offer:
          <br />
          Start Date: [Proposed Start Date]
          <br /> Salary: [Salary Amount] per [year/month/hour]
          <br /> Benefits: [Brief details, e.g., health insurance, 401(k), PTO]
          <br /> Employment Type: [Full-Time/Part-Time/Contract]
          <br /> Please sign and return this letter by [Return Date] to confirm
          your
          <br />
          acceptance. If you have any questions, feel free to contact me at
          [Your Phone Number]
          <br /> or [Your Email Address]. We look forward to having you on our
          team! Sincerely, <br />
          [Your Name]
          <br /> [Your Title]
          <br /> [Company Name]
          <br />
          [Your Phone Number]
          <br /> [Your Email Address]
          <br />
        </p>

        <br />
        {/* <span className="d-flex column">
          <p className="col-5">SIGNATURE: </p>{" "}
          <p className="col-5"> DATE:{formatDate(currentDate)}</p>
        </span> */}
        <p>
          Acceptance of Offer I, [Candidate’s Name], accept the offer of
          employment for the position of [Job Title].
          <br />
          Signature: ___________________________
          <br />
          Date: _______________________________
        </p>
      </div>
    </div>
  );
};

const OfferLetter = ({ studentName, setPdfBlob, setHandlePdf }) => {

  const [load, setLoad] = useState(false);
  
  const generatePdf = async () => {
    try {
      const textContentRef = document.getElementById("pdf-container");
      const mainCanvas = await html2canvas(textContentRef, { scale: 4 });

      const overlayCanvas = document.createElement("canvas");
      overlayCanvas.width = mainCanvas.width;
      overlayCanvas.height = mainCanvas.height;
      const overlayContext = overlayCanvas.getContext("2d");

      overlayContext.drawImage(mainCanvas, 0, 0);

      const finalPdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
      });

      const compressedDataURL = overlayCanvas.toDataURL("image/jpeg", 0.8);
      finalPdf.addImage(
        compressedDataURL,
        "JPEG",
        10,
        10,
        finalPdf.internal.pageSize.width - 40,
        (overlayCanvas.height * (finalPdf.internal.pageSize.width - 40)) /
          overlayCanvas.width
      );

      const pdfBlob = finalPdf.output("blob");

      setPdfBlob(pdfBlob);
      setHandlePdf(false);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    generatePdf();
  }, []);
  return (
    <span className="enrollment-container">
      {load && (
        <div className="application-loader">
          <p>Loading...</p>
        </div>
      )}
      <PdfContent studentName={studentName} />
    </span>
  );
};

export default OfferLetter;
