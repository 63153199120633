import download from "assets/images/cm/Cam/Download.png";
import sort from "assets/images/cm/Cam/sort.png";
import { ADMISSION_MANAGEMENT_URL } from "modules/admission-management/admission-management/components/create-admission/constants/admission-management-urls";
import { UserConstants } from "modules/user-management/constants/user-constants";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IoClose, IoEyeOutline } from "react-icons/io5";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import sessionService from "services/sessionstorage.service";
import "./Documents.css";
import { DOCUMENTS_URL_FOR_STUDENT } from "./constants/student-portal-documents-urls";
import { DOCUMENT_URLS } from "modules/documents/constants/documents-urls";
import AcademicTranscript from "modules/documents/components/enrollment-letter/academicTranscript";
import CLOAForDocument from "modules/documents/components/enrollment-letter/cloa";
import BreakLetter from "modules/documents/components/enrollment-letter/downloadBreakLetter";
import EnrollmentView from "modules/documents/components/enrollment-letter/enrollment";
import FeesInvoice from "modules/documents/components/enrollment-letter/feesInvoice";
import FLOAForDocument from "modules/documents/components/enrollment-letter/floa";
import OfficialReceipt from "modules/documents/components/enrollment-letter/officialReceipt";
import OfficialReceiptProvisional from "modules/documents/components/enrollment-letter/officialReceiptProvisional";
import TravelSupport from "modules/documents/components/enrollment-letter/travelSupport";
import WithDrawalLetter from "modules/documents/components/enrollment-letter/withDrawalLetter";
import { FiUpload } from "react-icons/fi";
import { ADMISSION_URL_FOR_STUDENT } from "modules/pre-onboarding/components/constants/admission-application-student-urls";
import { useNavigate } from "react-router-dom";
import betalogo from "assets/images/icons/beta.svg";
import { STUDENT_PORTAL_URL } from "modules/student-portal/constants/student-portal-urls";

const Documents = ({ admissionId, studentId, studentType, userId }) => {
  const date = new Date();
  const requestDate = new Date(date).toISOString().split("T")[0];
  const navigate = useNavigate();

  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);
  const [isCloaFloaUpload, setIsCloaFloaUpload] = useState(false);
  const [isEnrollmentUpload, setIsEnrollmentUpload] = useState(false);
  const [isProvisionalUpload, setIsProvisionalUpload] = useState(false);
  const [isActiveUpload, setIsActiveUpload] = useState(false);

  const [isStatusModalOpen, setStatusModalOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [modalName, setModalName] = useState("");
  const [modalData, setModalData] = useState({});
  const [comments, setComments] = useState("");
  const [docSort, setDocSort] = useState(true);
  const [paymentReceiptName, setPaymentReceiptName] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState("");
  const [docName, setDocName] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [downloadType, setDownloadType] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const userData = sessionService.getUserData("userData");

  const isStudent = userData?.role?.name === "student";

  const [data, setData] = useState([]);
  useEffect(() => {
    axiosService
      .get(
        `${STUDENT_PORTAL_URL.GET_STUDENT_MODULE_MARKS}?studentId=${studentId}`
      )
      .then((response) => {
        const data = response?.data?.data;
        setData(data);
      })
      .catch(() => {});
  }, [studentId]);

  const handleSort = () => {
    var sortingData;
    if (docSort === false) {
      sortingData = rowData.sort((a, b) => {
        let ta = a.docName.toLowerCase();
        let tb = b.docName.toLowerCase();
        if (ta < tb) {
          return -1;
        }
        if (ta > tb) {
          return 1;
        }
        return 0;
      });
      setRowData([...sortingData]);
      setDocSort(!docSort);
    } else if (docSort === true) {
      sortingData = rowData.sort((a, b) => {
        return a.documentId - b.documentId;
      });
      setRowData([...sortingData]);
      setDocSort(!docSort);
    }
  };

  const getDocumentById = (id) => {
    axiosService
      .get(`${DOCUMENTS_URL_FOR_STUDENT.GET_DOCUMENTS_BY_ID}${id}`)
      .then((response) => {
        const data = response?.data?.data;
        setModalData(data);
      });
  };

  const handleEyeClick = (docName, type) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_ADMISSION_BY_ID}${admissionId}`)
      .then((response) => {
        const data = response?.data?.data;
        setDownloadType(type);
        setDocName(docName);
        setPdfData(data);
      });
  };
  const [loading, setLoading] = useState(false);
  const handleActiveClick = (docId) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_DOCUMENT_BY_ID}${docId}`)
      .then((response) => {
        const paymentReceiptUrl = response?.data?.data?.paymentReceipt;

        if (paymentReceiptUrl) {
          setLoading(true);
          fetch(paymentReceiptUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "ActivePolicy.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
              setLoading(false);
            });
        } else {
          console.error("Payment receipt URL not found in response");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setLoading(false);
      });
  };
  const handleEnrollClick = (docId) => {
    axiosService
      .get(`${DOCUMENT_URLS.GET_DOCUMENT_BY_ID}${docId}`)
      .then((response) => {
        const paymentReceiptUrl = response?.data?.data?.paymentReceipt;

        if (paymentReceiptUrl) {
          setLoading(true);
          fetch(paymentReceiptUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "EnrollmentLetter.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
              setLoading(false);
            });
        } else {
          console.error("Payment receipt URL not found in response");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching document:", error);
        setLoading(false);
      });
  };

  const [admissionData, setAdmissionData] = useState(null);
  useEffect(() => {
    const handleResponse = () => {
      axiosService
        .get(
          `${ADMISSION_URL_FOR_STUDENT.GET_ADMISSION_URL_FOR_STUDENT}${admissionId}`
        )
        .then((response) => {
          const data = response?.data?.data;
          setAdmissionData(data);
        });
    };
    handleResponse();
  }, [admissionId]);

  const onDrop = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setPaymentReceiptName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setPaymentReceipt(response.data.data);
          setPaymentReceiptName(acceptedFiles[0]?.name);
        } else {
          setPaymentReceiptName(null);
        }
      } catch (error) {
        setPaymentReceiptName(null);
      }
    }
  };
  const [activeFileUrl, setActiveFileUrl] = useState("");
  const [activeFileName, setActiveFileName] = useState("");
  const [contractFileUrl, setContractFileUrl] = useState("");
  const [provisionalFileUrl, setProvisionalFileUrl] = useState("");
  const [contractFileName, setContractFileName] = useState("");
  const [provisionalFileName, setProvisionalFileName] = useState("");

  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const onDropEnrollmentActive = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setActiveFileName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setActiveFileUrl(response.data.data);
          setActiveFileName(acceptedFiles[0]?.name);
        } else {
          setActiveFileName(null);
        }
      } catch (error) {
        setActiveFileName(null);
      }
    }
  };
  const onDropEnrollmentContract = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setContractFileName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setContractFileUrl(response.data.data);
          setContractFileName(acceptedFiles[0]?.name);
        } else {
          setContractFileName(null);
        }
      } catch (error) {
        setContractFileName(null);
      }
    }
  };
  const onDropProvisional = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setProvisionalFileName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setProvisionalFileUrl(response.data.data);
          setProvisionalFileName(acceptedFiles[0]?.name);
        } else {
          setProvisionalFileName(null);
        }
      } catch (error) {
        setProvisionalFileName(null);
      }
    }
  };
  const onDropCloaFloas = async (acceptedFiles) => {
    if (!acceptedFiles[0]) {
      toast.error("Please select a image file less than 2MB.");
    } else if (!acceptedFiles[0].type.startsWith("application/pdf")) {
      toast.error(" Invalid file format,Please upload .pdf files");
    } else {
      try {
        if (acceptedFiles[0]?.name?.length > 150) {
          toast.error(UserConstants.IMAGE_LENGTH);
          return;
        }
        setFileName(acceptedFiles[0]?.name);
        sessionService.isLoading("true");
        const response = await axiosService.postFile(
          `${ADMISSION_MANAGEMENT_URL.FILE_UPLOAD_URL}`,
          acceptedFiles[0],
          true
        );

        if (response.status === 200) {
          setFileUrl(response.data.data);
          setFileName(acceptedFiles[0]?.name);
        } else {
          setFileName(null);
        }
      } catch (error) {
        setFileName(null);
      }
    }
  };

  const handleSubmitRequest = async () => {
    try {
      const requestData = {
        document: {
          documentId: modalData?.documentId,
        },
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Requested document submitted successfully");
        setRefresh(!refresh);
      } else {
      }
    } catch (error) {}
  };
  const handleSubmitEnrollment = async () => {
    try {
      const requestData = {
        userId: userId,
        contractFileUrl: contractFileUrl,
        contractFileName: contractFileName,
        requestDate: requestDate,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.UPLOAD_ENROLLMENT_FILES}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Enrollment document Uploaded successfully");

        setRefresh(!refresh);
      }
    } catch (error) {}
  };

  const handleSubmitProvisional = async () => {
    try {
      const requestData = {
        userId: userId,
        contractFileUrl: provisionalFileUrl,
        contractFileName: provisionalFileName,
        requestDate: requestDate,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.UPLOAD_PROVISIONAL_FILES}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Enrollment document Uploaded successfully");

        setRefresh(!refresh);
      }
    } catch (error) {}
  };
  const handleSubmitActive = async () => {
    try {
      const requestData = {
        userId: userId,
        activeFileUrl: activeFileUrl,
        activeFileName: activeFileName,
        requestDate: requestDate,
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.UPLOAD_ACTIVE_FILES}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Active Policy Uploaded successfully");

        setRefresh(!refresh);
      }
    } catch (error) {}
  };
  const handleSubmitCloaFloa = async () => {
    try {
      const requestData = {
        userId: userId,
        fileUrl: fileUrl,
        fileName: fileName,
        requestDate: requestDate,

        type:
          modalName === "Conditional Letter of Acceptance" ? "CLOA" : "FLOA",
      };

      const response = await axiosService.post(
        `${DOCUMENTS_URL_FOR_STUDENT.UPLOAD_CLOA_FLOA_FILES}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Document Uploaded successfully");

        setRefresh(!refresh);
      } else {
      }
    } catch (error) {}
  };
  const user = sessionService.getUserData("userData");

  const addReminder = async () => {
    try {
      const requestData = {
        document: {
          documentId: modalData?.document?.documentId,
        },
        docRequestId: modalData?.docRequestId,
        requestDate: modalData?.requestDate,
        paymentReceipt: modalData?.paymentReceipt,
        paymentReceiptName: modalData?.paymentReceiptName,
        requestorComment: modalData?.requestorComment,
        user: {
          userId: user?.userId,
        },
      };

      const response = await axiosService.put(
        `${DOCUMENTS_URL_FOR_STUDENT.ADD_REMINDER}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Reminder added successfully");
        setRefresh(!refresh);
      } else {
      }
    } catch (error) {}
  };
  const handleUploadReceipt = async () => {
    try {
      const requestData = {
        document: {
          documentId: modalData?.document?.documentId,
        },
        docRequestId: modalData?.docRequestId,
        requestDate: requestDate,
        paymentReceipt: paymentReceipt,
        paymentReceiptName: paymentReceiptName,
        requestorComment: comments,
        user: {
          userId: user?.userId,
        },
      };

      const response = await axiosService.put(
        `${DOCUMENTS_URL_FOR_STUDENT.REQUEST_VERIFICATION_URL}`,
        requestData
      );
      if (response.status === 200) {
        toast.success("Document updated successfully");
      } else {
      }
    } catch (error) {}
  };
  const onClose = () => {
    setVerificationModalOpen(false);
    setStatusModalOpen(false);
    setPaymentReceipt("");
    setPaymentReceiptName("");
    setIsCloaFloaUpload(false);
    setIsEnrollmentUpload(false);
    setIsProvisionalUpload(false);
    setIsActiveUpload(false);
    setModalData("");
    setComments("");
    setModalName("");
    setFileUrl("");
    setFileName("");
    setActiveFileName("");
    setActiveFileUrl("");
    setContractFileName("");
    setProvisionalFileName("");
    setContractFileUrl("");
    setProvisionalFileUrl("");
  };

  useEffect(() => {
    if (studentId) {
      axiosService
        .get(
          `${DOCUMENTS_URL_FOR_STUDENT.GET_DOCUMENTS_URL_FOR_STUDENTID}${studentId}`
        )
        .then((response) => {
          const data = response?.data?.data;

          setRowData(data);
        });
    } else {
      axiosService
        .get(`${DOCUMENTS_URL_FOR_STUDENT.GET_DOCUMENTS_URL}`)
        .then((response) => {
          const data = response?.data?.data;
          setRowData(data);
        });
    }
  }, [refresh]);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const timeString = new Intl.DateTimeFormat("en-US", options).format(date);

    const formattedDate = `${date.getFullYear()}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;

    return `${timeString} on ${formattedDate}`;
  }

  return (
    <div className="view-leads-table-container">
      {!studentId && (
        <div className="student-ps-top sticky-header">
          <div className="d-flex align-items-center gap-1 ">
            <span className="lead-table-title-text">My Documents </span>
          </div>
        </div>
      )}
      <div
        className="student-portal-inner-main-container "
        style={{ width: "100%", overflow: "unset", height: "auto" }}
      >
        <div
          className="custom-scrollbar"
          style={{ overflowX: "auto", overflowY: "auto" }}
        >
          <table class="Documents-table">
            <thead>
              <tr>
                <th
                  className="pre-student-doc-table-head"
                  style={{ position: "relative", paddingRight: "25px" }}
                  onClick={handleSort}
                >
                  Document Name
                  <img src={sort} id="Documents-sorting-Arrow" />
                </th>
                <th className="pre-student-doc-table-head">Status</th>
                <th className="pre-student-doc-table-head">
                  Approved/Rejected By
                </th>
                <th className="pre-student-doc-table-head">View</th>
                {isStudent && <th>Download</th>}
                {!isStudent && <th>Upload</th>}
              </tr>
            </thead>
            <tbody>
              {rowData.map(
                (a, i) =>
                  !(
                    studentType === "OSAP" &&
                    (a.docName === "Letter of Acceptance" ||
                      a.docName === "Conditional Letter of Acceptance")
                  ) && (
                    <tr
                      key={i}
                      className={
                        modalName === a.docName
                          ? "Documents-table-activerow"
                          : ""
                      }
                    >
                      <td>{a.docName}</td>
                      <td
                        style={
                          a.isActive === false
                            ? { color: "#7A7A7A", display: "flex" }
                            : { display: "flex" }
                        }
                      >
                        {/* Status indicators */}
                        {!(
                          a.docName === "Conditional Letter of Acceptance" ||
                          a.docName === "Letter of Acceptance" ||
                          a.docName === "Partial Transcript"
                        ) &&
                          (a.documentRequest?.status === "OPEN" ||
                          a.documentRequest?.status === "PENDING" ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#F69F3A",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : a.documentRequest?.status === "REJECTED" ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#F6413A",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : (a.isActive === true &&
                              a.needVerification === false) ||
                            a.documentRequest?.status === "APPROVED" ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#8C8C8C",
                                marginRight: "5px",
                              }}
                            ></div>
                          ))}

                        {!(
                          a.docName === "Conditional Letter of Acceptance" ||
                          a.docName === "Letter of Acceptance" ||
                          a.docName === "Partial Transcript"
                        ) &&
                          (a.isActive === false
                            ? "Inactive"
                            : a.documentRequest?.status === "APPROVED"
                            ? "Complete"
                            : a.documentRequest?.status === "OPEN"
                            ? "Open"
                            : a.documentRequest?.status === "PENDING"
                            ? "Pending"
                            : a.documentRequest?.status === "REJECTED"
                            ? "Rejected"
                            : a.isActive === true &&
                              a.needVerification === false
                            ? "Complete"
                            : "Not Started")}

                        {a.docName === "Conditional Letter of Acceptance" &&
                          ((admissionData &&
                            admissionData?.cloaStudentPayments[0] &&
                            admissionData?.cloaStudentPayments[0].isApproved) ||
                          (a.documentRequest &&
                            a.documentRequest.status === "APPROVED") ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "rgb(140, 140, 140)",
                                marginRight: "5px",
                              }}
                            ></div>
                          ))}

                        {a.docName === "Conditional Letter of Acceptance" &&
                          ((admissionData &&
                            admissionData?.cloaStudentPayments[0] &&
                            admissionData?.cloaStudentPayments[0].isApproved) ||
                          (a.documentRequest &&
                            a.documentRequest.status === "APPROVED")
                            ? "Complete"
                            : "Not Started")}
                        {a.docName === "Letter of Acceptance" &&
                          ((admissionData &&
                            admissionData?.studentPayments[0] &&
                            admissionData?.studentPayments[0].isApproved) ||
                          (a.documentRequest &&
                            a.documentRequest.status === "APPROVED") ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "rgb(140, 140, 140)",
                                marginRight: "5px",
                              }}
                            ></div>
                          ))}
                        {a.docName === "Letter of Acceptance" &&
                          ((admissionData &&
                            admissionData?.studentPayments[0] &&
                            admissionData?.studentPayments[0].isApproved) ||
                          (a.documentRequest &&
                            a.documentRequest.status === "APPROVED")
                            ? "Complete"
                            : "Not Started")}
                        {a.docName === "Partial Transcript" &&
                          (data && data.length === 0 ? (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "rgb(140, 140, 140)",
                                marginRight: "5px",
                              }}
                            ></div>
                          ) : (
                            <div
                              style={{
                                width: "6px",
                                height: "19px",
                                borderRadius: "2px",
                                backgroundColor: "#21BC53",
                                marginRight: "5px",
                              }}
                            ></div>
                          ))}

                        {a.docName === "Partial Transcript" &&
                          (data && data.length === 0
                            ? "Not Started"
                            : "Complete")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {a.documentRequest?.facultyApproved?.firstName
                          ? a.documentRequest?.facultyApproved?.firstName
                          : "-"}
                      </td>
                      <td className="text-center p-0">
                        {modalName === a.docName ? (
                          <IoEyeOutline size={"16px"} color="#F69F3A" />
                        ) : a.isActive === false ? (
                          <IoEyeOutline size={"16px"} color="#4E4E4E" />
                        ) : (
                          <IoEyeOutline
                            onClick={() => handleEyeClick(a.docName, "view")}
                            size={"16px"}
                            color="#FFFFFF"
                          />
                        )}
                      </td>
                      {isStudent && (
                        <td>
                          {!(
                            a.docName === "Conditional Letter of Acceptance" ||
                            a.docName === "Letter of Acceptance" ||
                            a.docName === "Partial Transcript"
                          ) &&
                            (a.documentRequest?.status === "OPEN" ||
                              a.documentRequest?.status === "PENDING" ||
                              a.documentRequest?.status === "REJECTED") && (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  getDocumentById(
                                    a.documentRequest?.docRequestId
                                  );
                                  setStatusModalOpen(true);
                                  setModalName(a.docName);
                                }}
                              >
                                Check Status
                              </a>
                            )}
                          {!(
                            a.docName === "Conditional Letter of Acceptance" ||
                            a.docName === "Letter of Acceptance" ||
                            a.docName === "Partial Transcript"
                          ) &&
                            a.isActive === true &&
                            a.needVerification === true &&
                            !a.documentRequest && (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  setVerificationModalOpen(true);
                                  setModalData(a);
                                  setModalName(a.docName);
                                }}
                              >
                                Request verification
                              </a>
                            )}
                          {a.docName === "Conditional Letter of Acceptance" &&
                            ((a.documentRequest &&
                              a.documentRequest.status === "APPROVED") ||
                            (admissionData &&
                              admissionData?.cloaStudentPayments[0] &&
                              admissionData?.cloaStudentPayments[0]
                                .isApproved) ? (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  handleEyeClick(a.docName, "download");
                                }}
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                />
                                Download
                              </a>
                            ) : (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  setVerificationModalOpen(true);
                                  setModalData(a);
                                  setModalName(a.docName);
                                }}
                              >
                                Request verification
                              </a>
                            ))}
                          {a.docName === "Letter of Acceptance" &&
                            ((a.documentRequest &&
                              a.documentRequest.status === "APPROVED") ||
                            (admissionData &&
                              admissionData?.studentPayments[0] &&
                              admissionData?.studentPayments[0].isApproved) ? (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  handleEyeClick(a.docName, "download");
                                }}
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                />
                                Download
                              </a>
                            ) : (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  setVerificationModalOpen(true);
                                  setModalData(a);
                                  setModalName(a.docName);
                                }}
                              >
                                Request verification
                              </a>
                            ))}
                          {a.docName === "Partial Transcript" &&
                            (data.length === 0 ? (
                              <a className="download-text-document">NA</a>
                            ) : (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  handleEyeClick(a.docName, "download");
                                }}
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                />
                                Download
                              </a>
                            ))}

                          {a.docName === "Active Policy" &&
                            ((a.isActive === true &&
                              a.needVerification === false) ||
                              (a.documentRequest &&
                                a.documentRequest.status === "APPROVED")) && (
                              <a
                                className="download-text-document"
                                onClick={() => {
                                  handleActiveClick(
                                    a?.documentRequest?.docRequestId
                                  );
                                }}
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                />
                                Download
                              </a>
                            )}
                          {a.docName === "Enrollment Letter" &&
                            ((a.isActive === true &&
                              a.needVerification === false) ||
                              (a.documentRequest &&
                                a.documentRequest.status === "APPROVED")) && (
                              <a
                                className="download-text-document"
                                onClick={() =>
                                  handleEnrollClick(
                                    a.documentRequest?.docRequestId
                                  )
                                }
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                  alt="Download Icon"
                                />
                                Download
                              </a>
                            )}

                          {!(
                            a.docName === "Conditional Letter of Acceptance" ||
                            a.docName === "Letter of Acceptance" ||
                            a.docName === "Active Policy" ||
                            a.docName === "Enrollment Letter"
                          ) &&
                            ((a.isActive === true &&
                              a.needVerification === false) ||
                              a.documentRequest?.status === "APPROVED") && (
                              <a
                                className="download-text-document"
                                onClick={() =>
                                  handleEyeClick(a.docName, "download")
                                }
                              >
                                <img
                                  src={download}
                                  style={{ marginRight: "4px" }}
                                />
                                Download
                              </a>
                            )}

                          {a.isActive === false && (
                            <a className="download-text-document">NA</a>
                          )}
                        </td>
                      )}
                      {!isStudent &&
                        (a.docName === "Conditional Letter of Acceptance" ||
                          a.docName === "Letter of Acceptance" ||
                          a.docName === "Enrollment Letter" ||
                          a.docName === "Provisional Receipt" ||
                          a.docName === "Active Policy") && (
                          <td className="text-center">
                            {a.docName === "Conditional Letter of Acceptance" &&
                              admissionData &&
                              admissionData.cloaStudentPayments &&
                              (admissionData.cloaStudentPayments.length === 0 ||
                                !admissionData.cloaStudentPayments[0]
                                  ?.isApproved) && (
                                <a
                                  className="download-text-document"
                                  onClick={() => {
                                    setIsCloaFloaUpload(true);
                                    setModalData(a);
                                    setModalName(a.docName);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Conditional Letter of Acceptance" &&
                              admissionData &&
                              admissionData?.cloaStudentPayments[0] &&
                              admissionData?.cloaStudentPayments[0]
                                ?.isApproved && (
                                <a
                                  className="download-text-document"
                                  style={{ opacity: "0.5", cursor: "default" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Letter of Acceptance" &&
                              admissionData &&
                              admissionData.studentPayments &&
                              (admissionData.studentPayments.length === 0 ||
                                !admissionData.studentPayments[0]
                                  ?.isApproved) && (
                                <a
                                  className="download-text-document"
                                  onClick={() => {
                                    setIsCloaFloaUpload(true);
                                    setModalData(a);
                                    setModalName(a.docName);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Letter of Acceptance" &&
                              admissionData &&
                              admissionData?.studentPayments[0] &&
                              admissionData?.studentPayments[0]?.isApproved && (
                                <a
                                  className="download-text-document"
                                  style={{ opacity: "0.5", cursor: "default" }}
                                >
                                  <FiUpload />
                                </a>
                              )}
                            {a.docName === "Enrollment Letter" &&
                              !a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  onClick={() => {
                                    setIsEnrollmentUpload(true);
                                    setModalData(a);
                                    setModalName(a.docName);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Enrollment Letter" &&
                              a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  style={{ opacity: "0.5", cursor: "default" }}
                                >
                                  <FiUpload />
                                </a>
                              )}
                            {a.docName === "Provisional Receipt" &&
                              !a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  onClick={() => {
                                    setIsProvisionalUpload(true);
                                    setModalData(a);
                                    setModalName(a.docName);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Provisional Receipt" &&
                              a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  style={{ opacity: "0.5", cursor: "default" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Active Policy" &&
                              !a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  onClick={() => {
                                    setIsActiveUpload(true);
                                    setModalData(a);
                                    setModalName(a.docName);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FiUpload />
                                </a>
                              )}

                            {a.docName === "Active Policy" &&
                              a.documentRequest && (
                                <a
                                  className="download-text-document"
                                  style={{ opacity: "0.5", cursor: "default" }}
                                >
                                  <FiUpload />
                                </a>
                              )}
                          </td>
                        )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div className="Documents-footer">
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#8C8C8C",
                  marginRight: "5px",
                }}
              ></div>
              <span>Inactive (or) Not Started:</span>
            </div>
            <span>
              Either document is not available yet or student has made no
              request yet
            </span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#F6413A",
                  marginRight: "5px",
                }}
              ></div>
              <span>Rejected:</span>
            </div>
            <span>Request has been rejected from staff/faculty side</span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#F69F3A",
                  marginRight: "5px",
                }}
              ></div>
              <span>Pending (or) Open:</span>
            </div>
            <span>Request is pending action from staff/faculty side</span>
          </div>
          <div className="document-footer-row-container">
            <div>
              <div
                style={{
                  width: "6px",
                  height: "19px",
                  borderRadius: "2px",
                  backgroundColor: "#21BC53",
                  marginRight: "5px",
                }}
              ></div>
              <span>Complete:</span>{" "}
            </div>
            <span>Request is complete and document available for download</span>
          </div>
        </div>
      </div>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isVerificationModalOpen}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Verification for{" "}
                <span className="text-white">{modalData?.docName}</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Date of Request
                  </span>
                  <input
                    type="date"
                    className="documents-modal-input-date mt-1"
                    name="Date 0f Request"
                    defaultValue={requestDate}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Upload Document
                  </span>
                  <Dropzone onDrop={onDrop} multiple={false} maxSize={2097152}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            paymentReceiptName ? "text-white" : ""
                          }`}
                        >
                          {paymentReceiptName
                            ? paymentReceiptName
                            : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments/Queries if any{" "}
                    <span style={{ color: "#7B7B7B", fontSize: "12px" }}>
                      (max limit here in chars)
                    </span>
                  </span>

                  <textarea
                    className="documents-modal-input-text-area mt-1"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  (!paymentReceipt || !comments) && "opacity-50"
                }`}
                disabled={!paymentReceipt || !comments}
                type="button"
                onClick={() => {
                  handleSubmitRequest();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isStatusModalOpen}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Status for{" "}
                <span className="text-white">{modalName}</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-2 mt-2">
              <div className="d-flex">
                <div className="col-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Date of Request
                  </span>
                  <span className="faculty-student-portal-service-popup-value">
                    {modalData?.requestDate}
                  </span>
                </div>
                <div className="col-6 d-flex flex-column align-items-end">
                  <span className="admin-action-text-document-popup">
                    <BsFillInfoCircleFill
                      className="me-1"
                      color={modalData?.status === "OPEN" ? "F6413A" : "F69F3A"}
                    />
                    {modalData?.status === "OPEN" && "no action from admin yet"}
                    {modalData?.status === "PENDING" &&
                      "Request under process."}
                    {modalData?.status === "REJECTED" &&
                      "Pending student action"}
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-6 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Upload Document
                  </span>
                  <a
                    href={modalData?.paymentReceipt}
                    target="_blank"
                    style={{ color: "#F69F3A" }}
                    className="faculty-student-portal-service-popup-value text-decoration-underline"
                  >
                    {modalData?.paymentReceiptName}
                  </a>
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-label">
                    Comments/Queries
                  </span>
                  <span className="faculty-student-portal-service-popup-value">
                    {modalData?.requestorComment}
                  </span>
                </div>
              </div>

              {modalData?.status === "REJECTED" && (
                <div className="d-flex">
                  <div className="col-12 d-flex flex-column gap-1">
                    <div className="student-portal-modal-title text-end">
                      Rejected
                    </div>
                    <span className="faculty-student-portal-document-rejected-box">
                      {modalData?.rejectorComment}
                    </span>
                    <span
                      className="faculty-student-portal-service-popup-value text-end"
                      style={{ fontSize: "12px" }}
                    >
                      {formatTimestamp(modalData?.updatedOn)}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {modalData?.status === "REJECTED" ? (
              <div
                className="mt-2"
                style={{ borderTop: "1px solid #212226", position: "relative" }}
              >
                <div className="col-12 d-flex flex-column mt-2">
                  <span className="faculty-student-portal-service-popup-value">
                    Upload Document
                  </span>
                  <Dropzone onDrop={onDrop} multiple={false} maxSize={2097152}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            paymentReceiptName ? "text-white" : ""
                          }`}
                        >
                          {paymentReceiptName
                            ? paymentReceiptName
                            : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="col-12 d-flex flex-column mt-2">
                  <span className="faculty-student-portal-service-popup-value">
                    Comments
                  </span>
                  <input
                    className="documents-modal-input mt-1"
                    value={comments}
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  />
                </div>

                <div className="mt-3">
                  <button
                    className={`student-portal-cancel-button ${
                      (!paymentReceipt || !comments) && "opacity-50"
                    }`}
                    disabled={!paymentReceipt || !comments}
                    onClick={() => {
                      handleUploadReceipt();
                      onClose();
                    }}
                  >
                    Upload receipt
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="mt-2"
                style={{ borderTop: "1px solid #212226", position: "relative" }}
              >
                <div className="mt-3">
                  <button
                    className={`student-portal-cancel-button ${
                      modalData?.status === "PENDING" && "opacity-50"
                    }`}
                    type="button"
                    disabled={modalData?.status === "PENDING"}
                    onClick={() => {
                      addReminder();
                      onClose();
                    }}
                  >
                    Add a reminder
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isCloaFloaUpload}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Request Verification for{" "}
                <span className="text-white">{modalData?.docName}</span>
              </span>

              <IoClose
                color="#ffffff"
                style={{ cursor: "pointer" }}
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Upload Document
                  </span>
                  <Dropzone
                    onDrop={onDropCloaFloas}
                    multiple={false}
                    maxSize={2097152}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            fileName ? "text-white" : ""
                          }`}
                        >
                          {fileName ? fileName : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  !fileUrl && "opacity-50"
                }`}
                disabled={!fileUrl}
                type="button"
                onClick={() => {
                  handleSubmitCloaFloa();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isEnrollmentUpload}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Upload Document for
                <span className="text-white"> {modalData?.docName}</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Enrollment Letter
                  </span>
                  <Dropzone
                    onDrop={onDropEnrollmentContract}
                    multiple={false}
                    maxSize={2097152}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            contractFileName ? "text-white" : ""
                          }`}
                        >
                          {contractFileName
                            ? contractFileName
                            : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  !contractFileUrl && "opacity-50"
                }`}
                disabled={!contractFileUrl}
                type="button"
                onClick={() => {
                  handleSubmitEnrollment();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isProvisionalUpload}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Upload Document for
                <span className="text-white"> {modalData?.docName}</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Provisional Receipt
                  </span>
                  <Dropzone
                    onDrop={onDropProvisional}
                    multiple={false}
                    maxSize={2097152}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            provisionalFileName ? "text-white" : ""
                          }`}
                        >
                          {provisionalFileName
                            ? provisionalFileName
                            : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  !provisionalFileUrl && "opacity-50"
                }`}
                disabled={!provisionalFileUrl}
                type="button"
                onClick={() => {
                  handleSubmitProvisional();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        className="crm-modal"
        onRequestClose={onClose}
        isOpen={isActiveUpload}
        shouldFocusAfterRender={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-5 p-3"
            style={{ height: "auto", backgroundColor: "#333333" }}
          >
            <div className="student-portal-modal-header">
              <span className="student-portal-modal-title">
                Upload Document for
                <span className="text-white"> {modalData?.docName}</span>
              </span>

              <IoClose
                color="#ffffff"
                size={"23px"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="d-flex">
                <div className="col-12 d-flex flex-column">
                  <span className="faculty-student-portal-service-popup-value">
                    Active Policy
                  </span>
                  <Dropzone
                    onDrop={onDropEnrollmentActive}
                    multiple={false}
                    maxSize={2097152}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="asps-document-upload-field mt-1"
                      >
                        <input {...getInputProps()} />
                        <span
                          className={`asps-upload-input  ${
                            activeFileName ? "text-white" : ""
                          }`}
                        >
                          {activeFileName ? activeFileName : "No file selected"}
                        </span>

                        <span className="create-list-upload-input-choose">
                          Choose file
                        </span>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div
              className="mt-3 pt-3 d-flex gap-3"
              style={{ borderTop: "1px solid #212226", position: "relative" }}
            >
              <button
                className="student-portal-cancel-button"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                className={`student-portal-submit-button ${
                  !activeFileUrl && "opacity-50"
                }`}
                disabled={!activeFileUrl}
                type="button"
                onClick={() => {
                  handleSubmitActive();
                  onClose();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div style={{ position: "absolute", left: -9999 }}>
        {docName === "Enrollment Letter" && (
          <EnrollmentView
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Partial Transcript" && (
          <AcademicTranscript
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
            data={data}
          />
        )}
        {docName === "Withdrawal Letter" && (
          <WithDrawalLetter
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Break Letter" && (
          <BreakLetter
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Fees Receipt" &&
          (downloadType === "download" ? (
            navigate("/fee-finance/my-receipt")
          ) : downloadType === "view" ? (
            <OfficialReceipt
              downloadType={downloadType}
              close={() => {
                setDownloadType(null);
              }}
              pdfData={pdfData}
            />
          ) : null)}

        {docName === "Provisional Receipt" && (
          <OfficialReceiptProvisional
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Travel Support Document" && (
          <TravelSupport
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Conditional Letter of Acceptance" && (
          <CLOAForDocument
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Letter of Acceptance" && (
          <FLOAForDocument
            downloadType={downloadType}
            close={() => {
              setDownloadType(null);
            }}
            pdfData={pdfData}
          />
        )}
        {docName === "Fees Invoice" &&
          (downloadType === "download" ? (
            navigate("/fee-finance/my-invoice")
          ) : downloadType === "view" ? (
            <FeesInvoice
              downloadType={downloadType}
              close={() => {
                setDownloadType(null);
              }}
              pdfData={pdfData}
            />
          ) : null)}
      </div>

      {loading && (
        <div className="application-loader">
          <img alt="img" className="blink" src={betalogo} />
        </div>
      )}
    </div>
  );
};

export default Documents;
