import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import MyProfile from "../components/MyProfileComponent/MyProfile";
import sessionService from "services/sessionstorage.service";

function MyProfileRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = sessionService?.isUserLoggedIn();
  useEffect(() => {
    if (token && location.pathname === "/profile") {
      navigate("/profile/my-profile");
    }
  }, [location?.pathname, token]);
  return (
    <Routes>
      <Route path="my-profile" element={<MyProfile />} />
    </Routes>
  );
}

export default MyProfileRoutes;
