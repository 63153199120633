import { AgGridReact } from "ag-grid-react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";
import { axiosService } from "services/axios.service";
import EditLeavePopup from "../edit-leave-popup/EditLeavePopup";
import AddNewHolidayPopup from "../add-new-holiday-popup/AddNewHolidayPopup";
import "./HolidayAndLeaves.css";
import { HOLIDAY_LEAVES_CALENDAR_URLS } from "modules/hr-module/constants/holiday-leaves-calendar-urls";
import DeleteIcon from "assets/image-components/DeleteIcon";
import EditIcon2 from "assets/image-components/EditIcon2";
import { toast } from "react-toastify";
import sessionService from "services/sessionstorage.service";
import { Dropdown } from "react-bootstrap";

const HolidayAndLeaves = () => {
  const navigate = useNavigate();
  const date = new Date();
  const calendarRef = useRef(null);
  const [data, setData] = useState([]);
  const [editLeavePopUp, setEditLeavePopUp] = useState(false);
  const [addNewHolidayPopup, setAddNewHolidayPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [type, setType] = useState(true);
  const [cycle, setCycle] = useState();

  const [view, setView] = useState("table");

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  useEffect(() => {
    axiosService
      .get(
        `${HOLIDAY_LEAVES_CALENDAR_URLS.GET_HOLIDAYS_BY_YEAR}?year=${selectedYear}`
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          if (view === "table") {
            setData(responseData);
          } else {
            let modData = responseData?.map((a) => {
              return {
                start: a.holidayDate,
                color:
                  a.holidayType === "OPTIONALHOLIDAY"
                    ? "#606060"
                    : "GOVERNMENTHOLIDAY"
                    ? "#f69f3a"
                    : "",
                display: "background",
              };
            });
            setData(modData);
          }
        }
      });
  }, [selectedYear, cycle, view]);

  const DeleteLeave = async (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this lead?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const response = axiosService
            .delete(`${HOLIDAY_LEAVES_CALENDAR_URLS.DELETE_HOLIDAY}/${id}`)
            .then((response) => {
              if (response.status === 200) {
                toast.success("Holiday deleted successfully");
                setCycle(new Date());
              } else {
                toast.error("failed");
              }
            });
        } catch (error) {
          if (error.response) {
            toast.error(`${error.response.data.message.split(":")[1]?.trim()}`);
          }
        }
      }
    });
  };

  function generateYearOptions() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  }

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent !important",
      fontSize: "20px !important",
      border: "none !important",
      cursor: "pointer !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#F7931D !important",
      fontSize: "20px !important",
    }),
  };

  const [columnDefs] = useState([
    {
      headerName: "Date",
      field: "holidayDate",
    },
    {
      headerName: "Holiday Name",
      field: "holidayName",
    },

    {
      headerName: "Day of the week",
      field: "day",
    },

    {
      headerName: "Leave Type",
      // field: "holidayType",
      cellRenderer: function (params) {
        const rowData = params.data;
        return (
          <span>
            {rowData.holidayType === "GOVERNMENTHOLIDAY"
              ? "Government Holiday"
              : "Optional Holiday"}
          </span>
        );
      },
    },
    {
      headerName: "Actions",
      cellRenderer: function (params) {
        const rowData = params.data;
        return (
          <span style={{ display: "flex", gap: "20px" }}>
            <span
              onClick={() => {
                setType(false);
                setAddNewHolidayPopup(true);
                setSelectedData(params.data);
              }}
            >
              <EditIcon2 />
            </span>
            <span onClick={() => DeleteLeave(rowData.holidayId)}>
              <DeleteIcon color="#FFFFFF" />
            </span>
          </span>
        );
      },
    },
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    rowHeight: 38,
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const handleBroadcast = async () => {
    try {
      sessionService.isLoading("true");
      const response = await axiosService.getForLoad(
        `${HOLIDAY_LEAVES_CALENDAR_URLS.GET_BROADCAST}`
      );

      if (response.status === 200) {
        toast.success("Broadcasts sent to Mail Successfully!");
      }
    } catch (error) {
      throw new Error(`Error updating circular notification: ${error.message}`);
    } finally {
      sessionService.isLoading("false");
    }
  };
  const handleAlert = () => {
    Swal.fire({
      html: "Are you sure you want to Broadcast All the Holidays?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Send",
      cancelButtonText: "No, cancel",
      customClass: {
        popup: "my-custom-class",
        content: "custom-swal-text",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleBroadcast();
      }
    });
  };

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate("2024" + "-01-01");
    }
  }, [selectedYear]);

  return (
    <div className="view-leads-table-container">
      <div className="communication-management-head-container align-items-center sticky-header flex-row flex-wrap gap-2">
        <span className="communication-management-title-text">
          Holiday & Leaves
        </span>

        <div className="d-none d-md-flex align-items-center gap-3">
          <button
            className="communication-save-head-button"
            onClick={() => setEditLeavePopUp(true)}
          >
            Leave Table
          </button>
          <button
            className="communication-save-head-button"
            // onClick={() => {
            //     navigate("/hr-module/new-employee");
            // }}
            onClick={() => {
              setType(true);
              setAddNewHolidayPopup(true);
            }}
          >
            Add new holiday
          </button>
          <button
            className={`user-head-button ${
              data.length === 0 ? "user-head-button-disabled" : ""
            }`}
            onClick={() => {
              if (data.length > 0) {
                handleAlert();
              }
            }}
          >
            Holiday Broadcast
          </button>
        </div>

        <Dropdown className="d-md-none">
          <Dropdown.Toggle
            className="lead-table-head-button"
            id="dropdown-basic"
          >
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0">
            <Dropdown.Item onClick={() => setEditLeavePopUp(true)}>
              Leave Table
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setType(true);
                setAddNewHolidayPopup(true);
              }}
            >
              Add New Holiday
            </Dropdown.Item>
            <Dropdown.Item
              className={` ${data.length === 0 && "opacity-50"}`}
              disabled={data.length === 0}
              onClick={() => {
                handleAlert();
              }}
            >
              Holiday Broadcast
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="view-faculty-header d-flex justify-content-between flex-wrap gap-2 py-2">
        <div
          className="view-faculty-header-left holiday-leave-left border-0"
          style={{ fontSize: "20px" }}
        >
          Holiday List
          <Select
            value={
              selectedYear ? { value: selectedYear, label: selectedYear } : null
            }
            onChange={(option) => {
              setSelectedYear(option?.value);
            }}
            options={generateYearOptions()}
            isSearchable={false}
            classNamePrefix="react-select"
            styles={selectStyles}
            components={{
              DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
              IndicatorSeparator: null,
            }}
            placeholder="Select a year"
            menuPlacement="auto"
            menuPosition="fixed"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#FFFFFF",
            fontSize: "12px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (view === "table") {
              setView("calendar");
            } else {
              setView("table");
            }
          }}
        >
          <span>
            <BsCalendar3 style={{ height: "26px", width: "26px" }} />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "#FFFFFF",
              fontSize: "14px",
              paddingLeft: "10px",
            }}
          >
            <span>Calendar view</span>
          </span>
        </div>
      </div>
      {view === "table" ? (
        <div className="ag-theme-alpine-dark">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data}
            rowSelection="single"
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            gridOptions={gridOptions}
          />
        </div>
      ) : (
        <div className="Holiday-and-leave-fullcalendar custom-scrollbar">
          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              multiMonthPlugin,
            ]}
            initialView="multiMonthYear"
            themeSystem="standard"
            headerToolbar={{
              left: "prev,today,next",
              center: "title",
              right: "timeGridWeek,dayGridMonth",
            }}
            dayMinWidth="10px"
            dragScroll="true"
            views={{
              dayGridMonth: {
                titleFormat: { month: "long" },
                showNonCurrentDates: false,
                eventDisplay: "block",
              },
              timeGrid: {
                titleFormat: { day: "2-digit", month: "long", year: "numeric" },
                columnHeaderFormat: {
                  day: "numeric",
                  month: "numeric",
                  omitCommas: true,
                },
                dayHeaderFormat: {
                  day: "numeric",
                  month: "short",
                  weekday: "long",
                  omitCommas: true,
                },
                slotDuration: "01:00",
                slotLabelInterval: "01:00",
                slotLabelFormat: { hour: "2-digit", minute: "2-digit" },
              },
              multiMonth: {
                multiMonthMaxColumns: 4,
                multiMonthMinWidth: 200,
              },
            }}
            events={[...data]}
            eventBorderColor="#F69F3A"
            eventBackgroundColor="#473929"
            selectable="true"
            selectedYear={selectedYear}
          />
          <div className="d-flex gap-5 pb-4 px-2">
            <div style={{ display: "flex", gap: "5px", textWrap: "nowrap" }}>
              <span
                style={{
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#614428",
                }}
              />
              Govt Holidays
            </div>
            <div style={{ display: "flex", gap: "5px", textWrap: "nowrap" }}>
              <span
                style={{
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#343538",
                }}
              />
              Optional Holidays
            </div>
          </div>
        </div>
      )}
      {editLeavePopUp && (
        <EditLeavePopup
          open={editLeavePopUp}
          onClose={() => {
            setEditLeavePopUp(false);
          }}
        />
      )}

      {addNewHolidayPopup && (
        <AddNewHolidayPopup
          open={addNewHolidayPopup}
          type={type}
          setType={setType}
          selectedData={selectedData}
          onClose={() => {
            setAddNewHolidayPopup(false);
            setSelectedData(null);
            setCycle(new Date());
          }}
        />
      )}
    </div>
  );
};

export default HolidayAndLeaves;
