import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "services/axios.service";
import { ROLE_URLS } from "../../constants/user-role-urls";
import { UserRoleConstants } from "../../constants/user-role.constants";
import "./AddRole.css";
import { IoIosArrowBack } from "react-icons/io";
function AddRole() {
  const navigate = useNavigate();
  const location = useLocation();
  const getRoleId = location?.state?.roleId;
  const pages = [
    "CRM",
    "Admission Management",
    "Pre-onBoarding",
    "System Administration",
    "Communication Management",
    "Circulars",
    "News",
    "QueriesFeedbackChat",
    "Forums",
    "Discussions",
    "Pre-Student Portal",
    "Pre-Student-Docs",
    "Student Portal",
    "Student Home",
    "Profile",
    "Documents",
    "Extracurricular",
    "Schedule",
    "Partial Transcript",
    "Feedback",
    "Participation",
    "Services",
    "Policies",
    "All Students",
    "All Service Requests",
    "All Document Requests",
    "Academics Management",
    "Academics Home",
    "All Programs",
    "All Modules",
    "LookUp",
    "My Program",
    "Time-Table Management",
    "Student Calendar",
    "My Calendar",
    "Class Schedules",
    "Resources",
    "Fee And Finance",
    "My Invoices",
    "Fee Plans",
    "Student Invoices",
    "Sponser Invoices",
    "Attendance Management",
    "My Classes",
    "Class Attendance",
    "My Attendance (faculty)",
    "My Attendance (Student)",
    "Faculty Attendance",
    "Exams And MarkSheets",
    "My Exams",
    "My Exams (Faculty)",
    "Campaigns",
    "All Campaigns",
    "Campaign Messages",
    "Exam Modules",
    "Exam Dashboard",
    "All Exam",
    "New Exam",
    "Question Bank",
    "Manage Marks",
    "Hr",
    "Holiday Calendar & Leaves",
    "All Faculty",
    "Profile-User",
    "My Profile",
  ];

  const [name, setName] = useState("");
  const [roleNameRequired, setRoleNameError] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [priority, setPriority] = useState(null);

  const viewMode = location?.state?.view;

  const initialPermissions = pages.map(() => ({
    fullAccess: false,
    view: false,
    create: false,
    edit: false,
    delete: false,
  }));

  const [permissions, setPermissions] = useState(initialPermissions);

  const handleReset = () => {
    if (roleId) {
      setRoleId(roleId);
      axiosService
        .get(`${ROLE_URLS.USER_ROLE_ID}${roleId}`)
        .then((response) => {
          const data = response.data;
          setName(data.data.name);
          setPriority(data.data.priority);
          const permissionsData = data.data.permissions;
          const initialPermissions = pages.map((page) => {
            const pagePermissions = {
              fullAccess: false,
              view: false,
              create: false,
              edit: false,
              delete: false,
            };

            permissionsData.forEach((permission) => {
              if (permission.startsWith(page)) {
                const permissionType = permission.split(": ")[1];
                pagePermissions[permissionType] = true;
              }
            });

            return pagePermissions;
          });
          setPermissions(initialPermissions);
        });
    } else {
      setName("");
      setPermissions("");
      setRoleNameError("");
    }
  };

  useEffect(() => {
    if (getRoleId) {
      setRoleId(getRoleId);
      axiosService
        .get(`${ROLE_URLS.USER_ROLE_ID}${getRoleId}`)
        .then((response) => {
          const data = response.data;
          setName(data.data.name);
          setPriority(data.data.priority);
          const permissionsData = data.data.permissions;
          const initialPermissions = pages.map((page) => {
            const pagePermissions = {
              fullAccess: false,
              view: false,
              create: false,
              edit: false,
              delete: false,
            };
            permissionsData.forEach((permission) => {
              if (permission.startsWith(page)) {
                const permissionType = permission.split(": ")[1];
                pagePermissions[permissionType] = true;
              }
            });
            return pagePermissions;
          });
          setPermissions(initialPermissions);
        });
    }
  }, [roleId]);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setRoleNameError("");
  };

  const handleSelectAll = (pageIndex, permissionType) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = [...prevPermissions];

      if (permissionType === "fullAccess") {
        updatedPermissions[pageIndex] = {
          ...updatedPermissions[pageIndex],
          fullAccess: !updatedPermissions[pageIndex]?.fullAccess,
          view: !updatedPermissions[pageIndex]?.fullAccess,
          create: !updatedPermissions[pageIndex]?.fullAccess,
          edit: !updatedPermissions[pageIndex]?.fullAccess,
          delete: !updatedPermissions[pageIndex]?.fullAccess,
        };
      } else {
        updatedPermissions[pageIndex][permissionType] =
          !prevPermissions[pageIndex][permissionType];
        updatedPermissions[pageIndex].fullAccess =
          updatedPermissions[pageIndex].view &&
          updatedPermissions[pageIndex].create &&
          updatedPermissions[pageIndex].edit &&
          updatedPermissions[pageIndex].delete;
      }

      return updatedPermissions;
    });
  };

  const handlePermissionChange = (pageIndex, permissionType) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = [...prevPermissions];
      const shouldCheck = !prevPermissions[pageIndex][permissionType];

      if (permissionType === "view" && !shouldCheck) {
        updatedPermissions[pageIndex] = {
          ...prevPermissions[pageIndex],
          view: shouldCheck,
          create: false,
          edit: false,
          delete: false,
          fullAccess: false,
        };
      } else {
        updatedPermissions[pageIndex] = {
          ...prevPermissions[pageIndex],
          [permissionType]: shouldCheck,
        };
        if (
          permissionType === "edit" ||
          permissionType === "delete" ||
          permissionType === "create"
        ) {
          updatedPermissions[pageIndex].view = true;
        }
        if (
          !updatedPermissions[pageIndex].create ||
          !updatedPermissions[pageIndex].edit ||
          !updatedPermissions[pageIndex].delete
        ) {
          updatedPermissions[pageIndex].fullAccess = false;
        }
      }

      return updatedPermissions;
    });
  };

  const saveRole = async () => {
    if (roleId) {
      const permissionData = [];

      const modulePermissions = [];
      for (let index = 0; index < permissions.length; index++) {
        const permission = permissions[index];
        for (const key in permission) {
          if (permission.hasOwnProperty(key) && permission[key] === true) {
            modulePermissions.push(`${pages[index]}: ${key}`);
          }
        }

        if (modulePermissions.length > 0) {
          permissionData.push(modulePermissions.join(", "));
          setPermissions(modulePermissions);
        }
      }
      const roleData = {
        roleId,
        priority,
        name,
        permissions: modulePermissions,
      };
      try {
        const response = await axiosService.put(
          `${ROLE_URLS.USER_ROLE_URL}`,
          roleData
        );

        if (response.status === 200) {
          toast.success(UserRoleConstants.ROLE_UPDATE_SUCCESS);
          navigate("/user-management/role-list");
        } else {
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
        }
      }
    } else {
      if (!name) {
        setRoleNameError(UserRoleConstants.ROLE_NAME_REQUIRED);
        return;
      } else {
        setRoleNameError("");
      }
      const permissionData = [];
      const modulePermissions = [];
      for (let index = 0; index < permissions.length; index++) {
        const permission = permissions[index];
        for (const key in permission) {
          if (permission.hasOwnProperty(key) && permission[key] === true) {
            modulePermissions.push(`${pages[index]}: ${key}`);
          }
        }

        if (modulePermissions.length > 0) {
          permissionData.push(modulePermissions.join(", "));
          setPermissions(modulePermissions);
        }
      }
      const roleData = {
        roleId,
        priority,
        name,
        permissions: modulePermissions,
      };
      try {
        const response = await axiosService.post(
          `${ROLE_URLS.USER_ROLE_URL}`,
          roleData
        );

        if (response.status === 200) {
          toast.success(UserRoleConstants.ROLE_SUCCESS);
          navigate("/user-management/role-list");
        } else {
        }
      } catch (error) {
        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } else {
        }
      }
    }
  };

  const indentedPages = [
    "Circulars",
    "News",
    "QueriesFeedbackChat",
    "Forums",
    "Discussions",
    "Student Home",
    "Profile",
    "Documents",
    "Extracurricular",
    "Schedule",
    "Partial Transcript",
    "Feedback",
    "Participation",
    "Services",
    "Policies",
    "All Students",
    "All Service Requests",
    "All Document Requests",
    "Academics Home",
    "All Programs",
    "All Modules",
    "LookUp",
    "My Program",
    "Student Calendar",
    "My Calendar",
    "Class Schedules",
    "Resources",
    "My Invoices",
    "Fee Plans",
    "Student Invoices",
    "Sponser Invoices",
    "My Classes",
    "Class Attendance",
    "My Attendance (faculty)",
    "My Attendance (Student)",
    "Faculty Attendance",
    "My Exams",
    "My Exams (Faculty)",
    "All Campaigns",
    "Campaign Messages",
    "Exam Dashboard",
    "All Exam",
    "New Exam",
    "Question Bank",
    "Manage Marks",
    "Holiday Calendar & Leaves",
    "All Faculty",
    "Pre-Student-Docs",
    "My Profile",
  ];

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="user-main-container">
      <div className="user-head-container sticky-header flex-row flex-wrap gap-1">
        <div className="d-flex align-items-center gap-1">
          <span className="user-title-text">
            <span onClick={handleGoBack} style={{ cursor: "pointer" }}>
              {" "}
              <IoIosArrowBack size={20} />
            </span>{" "}
            {viewMode ? "View Role" : roleId ? "Edit Role" : "Add Role"}
          </span>
        </div>
        <div className="d-flex align-items-center gap-1">
          {!viewMode && (
            <button className="user-save-head-button" onClick={saveRole}>
              {roleId ? "Update" : "Save"}
            </button>
          )}
          {!viewMode && (
            <button className="user-head-button" onClick={handleReset}>
              Reset
            </button>
          )}
          <button
            className="user-head-button"
            onClick={() => navigate("/user-management/role-list")}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center gap-3 mt-3">
        <span className="w-100 pb-2">
          <input
            value={name}
            onChange={handleNameChange}
            className="user-head-input-role"
            placeholder="Role Name"
            disabled={viewMode}
          />
          <span className="auth-login-error-text">{roleNameRequired}</span>
          <span className="user-search-icon-container"></span>
        </span>
      </div>

      <div
        className="mx-md-2 w-100 custom-scrollbar"
        style={{
          height: "calc(100vh - 190px)",
        }}
      >
        <table className="table" style={{ width: "100%", overflow: "auto" }}>
          <thead>
            <tr>
              <th className="add-role-title-text">
                <div>Module Name</div>
              </th>
              <th className="add-role-title-text text-center">
                <div>Full Access</div>
              </th>
              <th className="add-role-title-text text-center">
                <div>View</div>
              </th>
              <th className="add-role-title-text text-center">
                <div>Create</div>
              </th>
              <th className="add-role-title-text text-center">
                <div>Edit</div>
              </th>
              <th className="add-role-title-text text-center">
                <div>Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {pages.map((page, index) => (
              <tr key={index} style={{ height: "40px" }}>
                <td className={`add-user-label-text`}>
                  <span className={`${indentedPages.includes(page) && "ps-5"}`}>
                    {page}
                  </span>
                </td>
                {page !== "Pre-Student Portal" &&
                  page !== "Student Portal" &&
                  page !== "Communication Management" &&
                  page !== "Academics Management" &&
                  page !== "Time-Table Management" &&
                  page !== "Fee And Finance" &&
                  page !== "Attendance Management" &&
                  page !== "Exams And MarkSheets" &&
                  page !== "Campaigns" &&
                  page !== "Exam Modules" &&
                  page !== "Hr" &&
                  page !== "Profile-User" && (
                    <>
                      <td className="add-user-label-text text-center">
                        <input
                          type="checkbox"
                          checked={permissions[index]?.fullAccess}
                          onChange={() => handleSelectAll(index, "fullAccess")}
                          className="role-checkbox"
                          readOnly={viewMode}
                        />
                      </td>
                      <td className="add-user-label-text text-center">
                        <input
                          type="checkbox"
                          checked={permissions[index]?.view}
                          onChange={() => handlePermissionChange(index, "view")}
                          className="role-checkbox"
                          readOnly={viewMode}
                        />
                      </td>
                      <td className="add-user-label-text text-center">
                        <input
                          type="checkbox"
                          checked={permissions[index]?.create}
                          onChange={() =>
                            handlePermissionChange(index, "create")
                          }
                          className="role-checkbox"
                          readOnly={viewMode}
                        />
                      </td>
                      <td className="add-user-label-text text-center">
                        <input
                          type="checkbox"
                          checked={permissions[index]?.edit}
                          onChange={() => handlePermissionChange(index, "edit")}
                          className="role-checkbox"
                          readOnly={viewMode}
                        />
                      </td>
                      <td className="add-user-label-text text-center">
                        <input
                          type="checkbox"
                          checked={permissions[index]?.delete}
                          onChange={() =>
                            handlePermissionChange(index, "delete")
                          }
                          className="role-checkbox"
                          readOnly={viewMode}
                        />
                      </td>
                    </>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-end gap-4"></div>
    </div>
  );
}

export default AddRole;
