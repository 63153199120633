import { AUTH_URLS } from "core/auth/constants/auth-urls";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import beta_logo from "assets/images/icons/beta.svg";
import { axiosService } from "services/axios.service";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import PaymentReceipt from "core/auth/components/payment-gateway-home/payment-receipt/PaymentReceipt";

function PaymentStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const [resultData, setResultData] = useState(null);
  const [errorData, setErrorData] = useState(null);

  useEffect(() => {
    function getAllQueryParams() {
      const queryParams = {};
      const url = new URL(window.location.href);
      const queryString = url.hash.substring(1);
      const trimmedQueryString = queryString.replace("/payment-result?", "");
      const params = trimmedQueryString.split("&");
      for (let i = 0; i < params.length; i++) {
        const pair = params[i].split("=");
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1] || "");
        queryParams[key] = value;
      }
      return queryParams;
    }

    const queryParams = getAllQueryParams();

    const update = async () => {
      try {
        const response = await axiosService.put(`${AUTH_URLS.POST_PAYMENT}`, {
          totalAmount: queryParams?.trnAmount,
          isApproved: queryParams?.trnApproved === "1" ? true : false,
          transactionId: queryParams?.trnId,
          trnOrderNumber: Number(queryParams?.trnOrderNumber),
          trnCustomerName: queryParams?.trnCustomerName,
          feeInvoice: { feeInvoiceId: queryParams?.feeInvoiceId },
          paymentMethod: queryParams?.paymentMethod,
          payment: queryParams,
        });

        if (response.status === 200) {
          setResultData(response.data?.data);
          if (response?.data?.data?.isApproved) {
            setReceiptMail(true);
          }
        }
      } catch (error) {
        setErrorData(queryParams);
      }
    };

    if (queryParams?.trnOrderNumber && queryParams?.trnApproved) {
      update();
    }
  }, [location.search]);

  const [receiptDownload, setReceiptDownload] = useState(false);
  const [receiptMail, setReceiptMail] = useState(false);
  return (
    <div className="payment-gateway-home-container">
      <div className="row d-flex justify-content-center g-0">
        <div className="col-xl-7 col-lg-8 col-md-9 col-11">
          <div className="d-flex justify-content-center">
            <img src={beta_logo} />
          </div>
        </div>
        {(resultData || errorData) && (
          <div
            className="payment-form-container justify-content-center col-10 col-md-8 p-3"
            style={{ padding: 0, minHeight: "100px", display: "flex" }}
          >
            <div className="d-flex justify-content-center align-items-center gap-2 ">
              {resultData?.isApproved && (
                <>
                  <IoIosCheckmarkCircle color="green" fontSize={"30px"} />
                  <h5 className="fw-bold m-0">Payment Approved</h5>
                </>
              )}
              {!resultData?.isApproved && (
                <>
                  <RiCloseCircleFill color="red" fontSize={"30px"} />
                  <h5 className="fw-bold m-0">Payment Declined</h5>
                </>
              )}
            </div>

            <div className="d-flex flex-column gap-2 ">
              <div className="d-flex gap-2">
                <span>Name : </span>
                <span className="fw-semibold">
                  {resultData
                    ? resultData?.fullName
                    : errorData?.trnCustomerName}
                </span>
              </div>
              <div className="d-flex gap-2">
                <span>Email : </span>
                <span className="fw-semibold">
                  {resultData
                    ? resultData?.email
                    : errorData?.trnEmailAddress
                    ? errorData?.trnEmailAddress
                    : "-"}
                </span>
              </div>
              <div className="d-flex gap-2">
                <span>Amount : </span>
                <span className="fw-semibold">
                  {resultData ? resultData?.cadAmount : errorData?.trnAmount}{" "}
                  CAD $
                </span>
              </div>
              <div className="d-flex gap-2">
                <span>Fees : </span>
                <span className="fw-semibold">
                  {resultData ? resultData?.pay : "-"}
                </span>
              </div>
              <div className="d-flex gap-2">
                <span>Payment Mode : </span>
                <span className="fw-semibold">
                  {resultData
                    ? resultData?.paymentMethod === "CC"
                      ? "Credit Card"
                      : resultData?.paymentMethod
                    : errorData?.paymentMethod === "CC"
                    ? "Credit Card"
                    : errorData?.paymentMethod}
                </span>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <span>Status : </span>

                <span className="fw-semibold">
                  {resultData?.isApproved ? "Approved" : "Declined"}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              {resultData?.isApproved && (
                <button
                  className="proceed-to-payment-button"
                  onClick={() => setReceiptDownload(true)}
                >
                  DOWNLOAD RECEIPT
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {receiptDownload && (
        <div style={{ position: "absolute", zIndex: -1 }}>
          <PaymentReceipt
            resultData={resultData}
            methodClose={() => setReceiptDownload(false)}
            method={"save"}
          />
        </div>
      )}
      {receiptMail && (
        <div style={{ position: "absolute", zIndex: -1 }}>
          <PaymentReceipt
            resultData={resultData}
            methodClose={() => setReceiptMail(false)}
            method={"mail"}
          />
        </div>
      )}
    </div>
  );
}

export default PaymentStatus;
