import betalogo from "assets/images/icons/beta.svg";
import Header from "core/auth/components/header/Header";
import SideBar from "core/auth/components/sidebar/SideBar";
import LeadManagementRoutes from "modules/lead-mangement/components/routes/LeadManagementRoutes";
import PreOnboardingRoutes from "modules/pre-onboarding/components/routes/PreOnboardingRoutes";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import sessionstorageService from "services/sessionstorage.service";
import "./App.css";
import AuthRoutes from "./core/auth/components/routes/AuthRoutes";
import AdmissionRoutes from "./modules/admission-management/admission-management/components/routes/AdmissionRoutes";
import UserManagementRoutes from "./modules/user-management/components/routes/UserManagementRoutes";
import StudentPortalRoutes from "modules/student-portal/components/routes/StudentPortalRoutes";
import CommunicationManagmentRoutes from "modules/communication-management/routes/CommunicationManagmentRoutes";
import "utils/react-select-cutom.css";
import DocumentsRoutes from "modules/documents/routes/documentsRoutes";
import AcademicsManagementRoutes from "modules/academic-management/components/routes/AcademicsManagementRoutes";
import FeeFinanceRoutes from "modules/fee-finance/components/routes/FeeFinanceRoutes";
import TimeTableManagementRoutes from "modules/timetable-management/components/routes/TimeTableManagementRoutes";
import AttendanceManagementRoutes from "modules/attendance-management/routes/AttendanceManagementRoutes";
import CampaignRoutes from "modules/campaigns/routes/CampaignRoutes";
import ExamMarksheetsRoutes from "modules/exams-marksheets/routes/ExamMarksheetsRoutes";
import ExamModuleRoutes from "modules/exam-module/routes/ExamModuleRoutes";
import HrModuleRoutes from "modules/hr-module/components/routes/HrModuleRoutes";
import MyProfileRoutes from "modules/my-profile/routes/MyProfileRoutes";

function App() {
  const location = useLocation();
  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/enquiry-form" ||
      location.pathname === "/" ||
      location.pathname === "/OSAP" ||
      location.pathname === "/NVQ" ||
      location.pathname === "/ONTARIO" ||
      location.pathname === "/INTERNATIONAL" ||
      location.pathname === "/signup" ||
      location.pathname === "/signup/OSAP" ||
      location.pathname === "/signup/NVQ" ||
      location.pathname === "/signup/ONTARIO" ||
      location.pathname === "/signup/INTERNATIONAL" ||
      location.pathname === "/student-verification/home" ||
      location.pathname === "/student-verification/success" ||
      location.pathname === "/update-payment/paymentsubmission" ||
      location.pathname === "/qr-code" ||
      location.pathname === "/payment" ||
      location.pathname === "/payment-erp" ||
      location.pathname === "/payment-result"
    ) {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
    sessionstorageService.isUserLoggedIn();
  }, [location]);

  useEffect(() => {
    if (location.pathname != "/pre-onboarding/create-student-profile") {
      sessionstorageService.setStudentProfile(null);
    }
    if (location.pathname != "/student-portal/pre-student-view-documents") {
      sessionstorageService.setPreStudentData(null);
      sessionstorageService.setPreStudentDocumentId(null);
    }
  }, [location]);

  const [load, setLoad] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const data = sessionstorageService.getLoading();
      setLoad(data);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "#212226",
        display: "flex",
      }}
    >
      {sessionstorageService.isUserLoggedIn() && !hideNavbar && <SideBar />}
      <div
        style={{
          width: "100%",
          backgroundColor: "#212226",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        {sessionstorageService.isUserLoggedIn() && !hideNavbar && <Header />}
        {load === "true" && (
          <div className="application-loader">
            <img alt="img" className="blink" src={betalogo} />
          </div>
        )}

        <Routes>
          <Route path="*" element={<AuthRoutes />} />
          {sessionstorageService.isUserLoggedIn() && (
            <>
              <Route
                path="/user-management/*"
                element={<UserManagementRoutes />}
              />
              <Route
                path="/lead-management/*"
                element={<LeadManagementRoutes />}
              />
              <Route path="/documents/*" element={<DocumentsRoutes />} />
              <Route
                path="/admission-management/*"
                element={<AdmissionRoutes />}
              />
              <Route path="/campaigns/*" element={<CampaignRoutes />} />
              <Route
                path="/exam-marksheets/*"
                element={<ExamMarksheetsRoutes />}
              />
              <Route
                path="/pre-onboarding/*"
                element={<PreOnboardingRoutes />}
              />

              <Route
                path="/student-portal/*"
                element={<StudentPortalRoutes />}
              />
              <Route
                path="/communication-management/*"
                element={<CommunicationManagmentRoutes />}
              />
              <Route
                path="/academics-management/*"
                element={<AcademicsManagementRoutes />}
              />
              <Route path="/fee-finance/*" element={<FeeFinanceRoutes />} />
              <Route
                path="/timetable-management/*"
                element={<TimeTableManagementRoutes />}
              />
              <Route
                path="/attendance-management/*"
                element={<AttendanceManagementRoutes />}
              />
              <Route path="/exam-module/*" element={<ExamModuleRoutes />} />

              <Route path="/hr-module/*" element={<HrModuleRoutes />} />

              <Route path="/profile/*" element={<MyProfileRoutes />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
