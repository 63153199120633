import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosService } from "services/axios.service";
import { EXAMS_URL } from "modules/exams-marksheets/constants/exams-url";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { RxTriangleDown } from "react-icons/rx";
import { toast } from "react-toastify";

function FacultyExamLists() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [facultyId, setFacultyId] = useState(null);

  const isSaveButtonDisabled = (selectedStudent) => {
    return selectedStudent === null;
  };
  const handleClick = async () => {
    if (answerData === undefined) {
      try {
        const response = await axiosService.get(
          `${EXAMS_URL.GET_STUDENT_ANSWERS}/${facultyId}/${selectedStudent}`
        );

        if (response.status === 200) {
          const data = response?.data?.data;
          if (data?.answer) {
            navigate("/exam-marksheets/exam-answers", {
              state: data,
            });
          } else {
            toast.error("Answer not Submitted!");
          }
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      toast.error("Answer Already Corrected!");
    }
  };

  useEffect(() => {
    axiosService
      .get(`${EXAMS_URL.GET_ALL_STUDENT_ANSWERS}`)
      .then((response) => {
        const data = response?.data?.data;
        setData(data);
      });
  }, []);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [answerData, setAnswerData] = useState(null);
  const fetchData = async (id) => {
    try {
      const response = await axiosService.get(
        `${EXAMS_URL.GET_ALL_STUDENT_ANSWERS}/${id}`
      );
      const data = response?.data?.data;
      const studentList = data?.map((data) => ({
        data: data,
        value: data.master.studentId,
        label: data.master.studentId,
      }));
      if (studentList) {
        setStudentList(studentList);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function RatioCellRenderer(params) {
    const completedCount = params.data.completedStudentCount || 0;
    const totalCount = params.data.studentCount || 0;

    return `${completedCount}/${totalCount}`;
  }
  const columnDefs = [
    {
      field: "module.module.module.moduleName",
      headerName: "Exam Name",
    },
    {
      field: "module.scheduledDate",
      headerName: "ExamDate",
    },
    {
      field: "module.scheduledTime",
      headerName: "Exam Time",
    },
    {
      field: "module.duration",
      headerName: "Exam Duration",
    },
    // {
    //   field: "module.status",
    //   headerName: "Status",
    // },
    {
      headerName: "Attended",
      field: "completedStudentCount",
      cellRenderer: "ratioCellRenderer",
    },
  ];
  const gridOptions = {
    pagination: true,
    paginationPageSize: 10,
    components: {
      ratioCellRenderer: RatioCellRenderer,
    },
  };
  const [modalOpen, setModalOpen] = useState(false);
  function rowClicked(event) {
    setFacultyId(event?.data?.module?.facultyExamModuleId);
    fetchData(event?.data?.module?.facultyExamModuleId);
    setModalOpen(true);
  }

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  return (
    <div>
      <div className="communication-management-main-container">
        <div className="communication-management-head-container sticky-header-communication flex-sm-row gap-2">
          <div className="d-flex align-items-center gap-3">
            <span className="communication-management-title-text">
              Student Answers
            </span>
          </div>
        </div>
        <div className="ag-theme-alpine-dark">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={data}
            rowSelection="single"
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            gridOptions={gridOptions}
            onRowClicked={rowClicked}
          />
        </div>
      </div>
      <Modal
        className="crm-modal"
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-Con">
          <div
            className="col-11 col-md-6 p-3 custom-scrollbar"
            style={{ height: "auto", backgroundColor: "#212226" }}
          >
            <div className="flex-sm-row justify-content-between">
              <div className="new-conversation-popup-header">
                <span className="add-news-current-affair-title-text">
                  Student List
                </span>

                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <IoClose
                    color="#ffffff"
                    size={"23px"}
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  />
                </span>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #7a7a7a",
                fontSize: "14px",
                color: "#ffffff",
              }}
            >
              <div className="create-admission-inner-first-input-container">
                <label
                  className="lead-source-name"
                  style={{ color: "#f69f3a" }}
                >
                  Select Student
                </label>
                <Select
                  value={studentList.find(
                    (student) => student.value === selectedStudent
                  )}
                  onChange={(option) => {
                    setAnswerData(option.data?.answer);
                    setSelectedStudent(option.value);
                  }}
                  options={studentList}
                  isSearchable={false}
                  classNamePrefix="react-select-big"
                  components={{
                    DropdownIndicator: () => <RxTriangleDown color="#ffffff" />,
                    IndicatorSeparator: null,
                  }}
                  placeholder="Choose an option"
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 mt-3">
              <button
                className="communication-management-cancel-button"
                type="button"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className={`user-save-head-button ${
                  isSaveButtonDisabled(selectedStudent)
                    ? "user-save-head-button-disabled"
                    : ""
                }`}
                disabled={isSaveButtonDisabled(selectedStudent)}
                onClick={handleClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FacultyExamLists;
